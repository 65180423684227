<m-portlet>
  <!-- use ng-container[mPortletHeadTitle] for the title -->
  <ng-container mPortletHeadTitle>
    <div class="m-portlet__head-title">
      <h3 class="m-portlet__head-text">Tasks</h3>
    </div>
  </ng-container>

  <!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
  <ng-container mPortletHeadTools>
    <ul class="m-portlet__nav">
      <li
        class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
        m-dropdown-toggle="hover"
        aria-expanded="true"
      >
        <a href="#" class="m-portlet__nav-link m-portlet__nav-link--icon m-portlet__nav-link--icon-xl m-dropdown__toggle">
          <i class="la la-ellipsis-h m--font-brand"></i>
        </a>
        <div class="m-dropdown__wrapper" style="z-index: 101">
          <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" style="left: auto; right: 21.5px"></span>
          <div class="m-dropdown__inner">
            <div class="m-dropdown__body">
              <div class="m-dropdown__content">
                <ul class="m-nav">
                  <li class="m-nav__section m-nav__section--first">
                    <span class="m-nav__section-text">Quick Actions</span>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-share"></i>
                      <span class="m-nav__link-text">Activity</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-chat-1"></i>
                      <span class="m-nav__link-text">Messages</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-info"></i>
                      <span class="m-nav__link-text">FAQ</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                      <span class="m-nav__link-text">Support</span>
                    </a>
                  </li>
                  <li class="m-nav__separator m-nav__separator--fit"></li>
                  <li class="m-nav__item">
                    <a href="#" class="btn btn-outline-danger m-btn m-btn--pill m-btn--wide btn-sm">Cancel</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>

  <!-- use ng-container[mPortletBody] for the body content -->
  <ng-container mPortletBody>
    <div class="m-widget2">
      <div class="m-widget2__item m-widget2__item--primary">
        <div class="m-widget2__checkbox">
          <label class="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
            <input type="checkbox" />
            <span></span>
          </label>
        </div>
        <div class="m-widget2__desc">
          <span class="m-widget2__text"> Make ginger-dashboard Great Again.Lorem Ipsum Amet </span>
          <br />
          <span class="m-widget2__user-name">
            <a href="javascript:;" class="m-widget2__link"> By Bob </a>
          </span>
        </div>
        <div class="m-widget2__actions">
          <div class="m-widget2__actions-nav">
            <div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover">
              <a href="javascript:;" class="m-dropdown__toggle">
                <i class="la la-ellipsis-h"></i>
              </a>
              <div class="m-dropdown__wrapper">
                <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                <div class="m-dropdown__inner">
                  <div class="m-dropdown__body">
                    <div class="m-dropdown__content">
                      <ul class="m-nav">
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-share"></i>
                            <span class="m-nav__link-text">Activity</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-chat-1"></i>
                            <span class="m-nav__link-text">Messages</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-info"></i>
                            <span class="m-nav__link-text">FAQ</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                            <span class="m-nav__link-text">Support</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-widget2__item m-widget2__item--warning">
        <div class="m-widget2__checkbox">
          <label class="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
            <input type="checkbox" />
            <span></span>
          </label>
        </div>
        <div class="m-widget2__desc">
          <span class="m-widget2__text"> Prepare Docs For Metting On Monday </span>
          <br />
          <span class="m-widget2__user-name">
            <a href="javascript:;" class="m-widget2__link"> By Sean </a>
          </span>
        </div>
        <div class="m-widget2__actions">
          <div class="m-widget2__actions-nav">
            <div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover">
              <a href="javascript:;" class="m-dropdown__toggle">
                <i class="la la-ellipsis-h"></i>
              </a>
              <div class="m-dropdown__wrapper">
                <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                <div class="m-dropdown__inner">
                  <div class="m-dropdown__body">
                    <div class="m-dropdown__content">
                      <ul class="m-nav">
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-share"></i>
                            <span class="m-nav__link-text">Activity</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-chat-1"></i>
                            <span class="m-nav__link-text">Messages</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-info"></i>
                            <span class="m-nav__link-text">FAQ</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                            <span class="m-nav__link-text">Support</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-widget2__item m-widget2__item--brand">
        <div class="m-widget2__checkbox">
          <label class="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
            <input type="checkbox" />
            <span></span>
          </label>
        </div>
        <div class="m-widget2__desc">
          <span class="m-widget2__text"> Make Widgets Great Again.Estudiat Communy Elit </span>
          <br />
          <span class="m-widget2__user-name">
            <a href="javascript:;" class="m-widget2__link"> By Aziko </a>
          </span>
        </div>
        <div class="m-widget2__actions">
          <div class="m-widget2__actions-nav">
            <div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover">
              <a href="javascript:;" class="m-dropdown__toggle">
                <i class="la la-ellipsis-h"></i>
              </a>
              <div class="m-dropdown__wrapper">
                <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                <div class="m-dropdown__inner">
                  <div class="m-dropdown__body">
                    <div class="m-dropdown__content">
                      <ul class="m-nav">
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-share"></i>
                            <span class="m-nav__link-text">Activity</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-chat-1"></i>
                            <span class="m-nav__link-text">Messages</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-info"></i>
                            <span class="m-nav__link-text">FAQ</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                            <span class="m-nav__link-text">Support</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-widget2__item m-widget2__item--success">
        <div class="m-widget2__checkbox">
          <label class="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
            <input type="checkbox" />
            <span></span>
          </label>
        </div>
        <div class="m-widget2__desc">
          <span class="m-widget2__text"> Make ginger-dashboard Great Again.Lorem Ipsum </span>
          <br />
          <span class="m-widget2__user-name">
            <a href="javascript:;" class="m-widget2__link"> By James </a>
          </span>
        </div>
        <div class="m-widget2__actions">
          <div class="m-widget2__actions-nav">
            <div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover">
              <a href="javascript:;" class="m-dropdown__toggle">
                <i class="la la-ellipsis-h"></i>
              </a>
              <div class="m-dropdown__wrapper">
                <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                <div class="m-dropdown__inner">
                  <div class="m-dropdown__body">
                    <div class="m-dropdown__content">
                      <ul class="m-nav">
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-share"></i>
                            <span class="m-nav__link-text">Activity</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-chat-1"></i>
                            <span class="m-nav__link-text">Messages</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-info"></i>
                            <span class="m-nav__link-text">FAQ</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                            <span class="m-nav__link-text">Support</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-widget2__item m-widget2__item--danger">
        <div class="m-widget2__checkbox">
          <label class="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
            <input type="checkbox" />
            <span></span>
          </label>
        </div>
        <div class="m-widget2__desc">
          <span class="m-widget2__text"> Completa Financial Report For Emirates Airlines </span>
          <br />
          <span class="m-widget2__user-name">
            <a href="javascript:;" class="m-widget2__link"> By Bob </a>
          </span>
        </div>
        <div class="m-widget2__actions">
          <div class="m-widget2__actions-nav">
            <div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover">
              <a href="javascript:;" class="m-dropdown__toggle">
                <i class="la la-ellipsis-h"></i>
              </a>
              <div class="m-dropdown__wrapper">
                <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                <div class="m-dropdown__inner">
                  <div class="m-dropdown__body">
                    <div class="m-dropdown__content">
                      <ul class="m-nav">
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-share"></i>
                            <span class="m-nav__link-text">Activity</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-chat-1"></i>
                            <span class="m-nav__link-text">Messages</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-info"></i>
                            <span class="m-nav__link-text">FAQ</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                            <span class="m-nav__link-text">Support</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-widget2__item m-widget2__item--info">
        <div class="m-widget2__checkbox">
          <label class="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
            <input type="checkbox" />
            <span></span>
          </label>
        </div>
        <div class="m-widget2__desc">
          <span class="m-widget2__text"> Completa Financial Report For Emirates Airlines </span>
          <br />
          <span class="m-widget2__user-name">
            <a href="javascript:;" class="m-widget2__link"> By Sean </a>
          </span>
        </div>
        <div class="m-widget2__actions">
          <div class="m-widget2__actions-nav">
            <div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover">
              <a href="javascript:;" class="m-dropdown__toggle">
                <i class="la la-ellipsis-h"></i>
              </a>
              <div class="m-dropdown__wrapper">
                <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                <div class="m-dropdown__inner">
                  <div class="m-dropdown__body">
                    <div class="m-dropdown__content">
                      <ul class="m-nav">
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-share"></i>
                            <span class="m-nav__link-text">Activity</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-chat-1"></i>
                            <span class="m-nav__link-text">Messages</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-info"></i>
                            <span class="m-nav__link-text">FAQ</span>
                          </a>
                        </li>
                        <li class="m-nav__item">
                          <a href="" class="m-nav__link">
                            <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                            <span class="m-nav__link-text">Support</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</m-portlet>
