import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ILoadingDialogInput } from './loading-dialog.args';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.css']
})
export class LoadingDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILoadingDialogInput<any>) {
  }
  error = null

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.data.observer.subscribe((data) => {
     // console.log(data);
    }, (error) => {
      this.error = error;
      this.dialogRef.disableClose = false;
    }, () => {
      if(this.error) { return }
      this.dialogRef.close()
      this.data.success(true)
    })
  }

}
