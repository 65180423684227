<button mat-raised-button
        [color]="options.buttonColor"
        [class.active]="options.active"
        [class.fullWidth]="options.fullWidth"
        [class.mat-raised-button]="options.raised"
        [disabled]="options.active"
        class="custom-button horizontal-layout">

  <ng-container>
    <span class="content">
      <ng-content *ngIf="!options.active; else loadingTemplate"></ng-content>
    </span>
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="spinner-container">
      <mat-spinner class="spinner"
                  [diameter]="options.spinnerSize"
                  [color]="options.spinnerColor"
                  [mode]="options.mode"
                  [value]="options.value"></mat-spinner>
    </div>
  </ng-template>
</button>
