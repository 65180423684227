import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

// import { Papa } from 'ngx-papaparse';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { CoreModule } from './core/core.module';
import { AclService } from './core/services/acl.service';
import { UserService } from './core/services/user.service';
import { LogsService } from './core/services/logs.service';
import { UtilsService } from './core/services/utils.service';
import { CompanyService } from './core/services/company.service';
import { DownloadService } from './core/services/download.service';
import { ClipboardService } from './core/services/clipboard.sevice';
import { RequestInteceptor } from './core/auth/request.interceptor';
import { MessengerService } from './core/services/messenger.service';
import { DataTableService } from './core/services/datatable.service';
import { ClassInitService } from './core/services/class-init.service';
import { HeaderService } from './core/services/layout/header.service';
import { PageConfigService } from './core/services/page-config.service';
import { MenuConfigService } from './core/services/menu-config.service';
import { AuthenticationModule } from './core/auth/authentication.module';
import { QuickSearchService } from './core/services/quick-search.service';
import { ChartConfigService } from './core/services/chart-config.service';
import { SubheaderService } from './core/services/layout/subheader.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { MenuAsideService } from './core/services/layout/menu-aside.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { MenuHorizontalService } from './core/services/layout/menu-horizontal.service';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { SecondaryMenuConfigService } from './core/services/secondary-menu-config.service';
import { SecondaryMenuHorizontalService } from './core/services/layout/secondary-menu-horizontal.service';

import * as fromRoot from './reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { TRIP_DIALOGS } from '@trip/trips.module';
import { AppRoutingModule } from './app-routing.module';
import { DialogsModule } from '@dialogs/dialogs.module';
import { DetailsTripDialogComponent } from '@trip/dialogs/details-trip-dialog/details-trip-dialog.component';
import { FlightEditorDialogComponent } from '@trip/dialogs/details-trip-dialog/flight-editor-dialog/flight-editor-dialog.component';
import { DetailsPricesDialogComponent } from '@trip/dialogs/details-trip-dialog/details-prices-dialog/details-prices-dialog.component';

import { LayoutModule } from './content/layout/layout.module';
import { PartialsModule } from './content/partials/partials.module';
import { ShareDialogComponent } from './content/pages/expense-report/dialogs/share-dialog/share-dialog.component';
import { ValidateCodeDialogComponent } from './content/pages/auth/validate-code-dialog/validate-code-dialog.component';
import { ReceiptsDialogComponent } from './content/pages/expense-report/dialogs/receipts-dialog/receipts-dialog.component';
import { ForgotPasswordDialogComponent } from './content/pages/auth/forgot-password-dialog/forgot-password-dialog.component';
import { ChangePasswordDialogComponent } from './content/pages/auth/change-password-dialog/change-password-dialog.component';
import { EmployeeDetailsDialogComponent } from '@employee/dialogs/employee-details-dialog/employee-details-dialog.component';
import { ImportSuccessComponent } from './content/pages/settings/settings-main/employee-import/import-success/import-success.component';
import { ForgotPasswordResetDialogComponent } from './content/pages/auth/forgot-password-reset-dialog/forgot-password-reset-dialog.component';
import { CancelApprovalDialogComponent } from './content/pages/expense-report/dialogs/cancel-approval-dialog/cancel-approval-dialog.component';
import { UpdateApprovalDialogComponent } from './content/pages/expense-report/dialogs/update-approval-dialog/update-approval-dialog.component';


export const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
};
const MOMENT_FORMATS = {
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    DialogsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    AuthenticationModule,
    // Papa,
    NgxPermissionsModule.forRoot(),
    NgbModule,
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatPasswordStrengthModule.forRoot(),
    MatRadioModule,
    StoreModule.forRoot(fromRoot.reducer),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: "Ginger Dashboard",
      logOnly: environment.production,
    }),

    EffectsModule.forRoot([]),
    MatSlideToggleModule,
  ],
  providers: [
    AclService,
    LayoutConfigService,
    LayoutConfigStorageService,
    LayoutRefService,
    MenuConfigService,
    SecondaryMenuConfigService,
    PageConfigService,
    UserService,
    CompanyService,
    UtilsService,
    DownloadService,
    ClassInitService,
    MessengerService,
    ClipboardService,
    LogsService,
    ChartConfigService,
    QuickSearchService,
    DataTableService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },

    SubheaderService,
    HeaderService,
    MenuHorizontalService,
    SecondaryMenuHorizontalService,
    MenuAsideService,
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_FORMATS },
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: GestureConfig,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInteceptor,
      multi: true,
    },
  ],
  entryComponents: [
    ...TRIP_DIALOGS,
    ForgotPasswordResetDialogComponent,
    ImportSuccessComponent,
    ForgotPasswordDialogComponent,
    ChangePasswordDialogComponent,
    ValidateCodeDialogComponent,
    EmployeeDetailsDialogComponent,
    ReceiptsDialogComponent,
    DetailsTripDialogComponent,
    DetailsPricesDialogComponent,
    FlightEditorDialogComponent,
    ShareDialogComponent,
    CancelApprovalDialogComponent,
    UpdateApprovalDialogComponent,
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor() {
    const host = document.location.hostname.split(".");

    if (host[0] === "localhost") {
    } else if (host[1] === "gingerbit") {
      environment.api_endpoint = `https://api.gingerbit.co.il/api`;
      environment.api_health_insurance_endpoint = `https://api.gingerbit.co.il/api/health-insurance`;
      environment.api_currencies_endpoint = `https://api.gingerbit.co.il/api/currencies`;
      environment.api_demographics_endpoint = `https://api.gingerbit.co.il/api/demographics`;
      environment.api_aviation_endpoint = `https://api.gingerbit.co.il/api/aviation`;
      environment.api_migdal_endpoint = "https://api.gingerbit.co.il/integration/migdal"
    } else {
      // TODO: find solution for test env
      environment.api_endpoint = "https://new.api.dev.gingerbit.co.il/api";
      environment.api_health_insurance_endpoint = `https://new.api.dev.gingerbit.co.il/api/health-insurance`;
      environment.api_currencies_endpoint = `https://new.api.dev.gingerbit.co.il/api/currencies`;
      environment.api_demographics_endpoint = `https://new.api.dev.gingerbit.co.il/api/demographics`;
      environment.api_aviation_endpoint = `https://new.api.dev.gingerbit.co.il/api/aviation`;
      environment.api_migdal_endpoint = "https://new.api.dev.gingerbit.co.il/integration/migdal"
    }
  }
}
