import { MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay/index';

export abstract class AbstractDialogArgs<R = any, D = any> {
	public dialogOptions: MatDialogConfig<D> = {
		width: '40vw',
		minWidth: '50vw',
		minHeight: '40vh'
	};
	constructor(public data: D, callback?: (result: R) => void) {
		this.callback = callback;
		this.dialogOptions.data = this.data;
	}
	public updateDialogOptions(dialogOptions: MatDialogConfig<D>) {
		dialogOptions["data"]=this.dialogOptions.data;
		this.dialogOptions =dialogOptions;
	}
	abstract readonly component: ComponentType<any>;
	public readonly callback?: (data: R) => void;
}
