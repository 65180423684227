import { Injectable } from '@angular/core';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DownloadService } from './download.service';

export interface Doc
{

  _id?: string;
  createdAt?: Date;
  createdBy?: string;
  description?: string;
  fileType?: string;
  mimetype?: string;
  type: string;
  url?: string;
  fileEntry?: FileSystemFileEntry;
  sort?: number;
}

@Injectable()
export class UserService
{
  constructor(private http: HttpClient, private downloadService: DownloadService) { }

  downloadDocument(doc: Doc)
  {
    return this.http.get(`${environment.api_endpoint}/files/${doc.url}`, { observe: 'response', responseType: 'blob' }).
      pipe(tap((response) =>
      {
        this.downloadService.downloadBlob(doc.description, response.body);
      }));
  }
}
