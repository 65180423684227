import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { company } from "./ngrx/pages.reducer";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
// @ts-ignore
import * as fromAuth from "@auth/index.ts";
import { Store, select } from "@ngrx/store";
import { map, tap } from "rxjs/operators";
import moment from "moment";
import { Destination } from "@trip/models/destination.model";
import * as _ from "lodash";
import { DownloadService } from "src/app/core/services/download.service";

@Injectable({
  providedIn: "root",
})
export class PagesService {
  companyId: string;
  downloadFile(filePath: any, fileName: any) {
    if (filePath === "") {
      alert("not yet supported");
      return;
    }
    return this.http
      .post(
        `${environment.api_endpoint}/${filePath}}`,
        {},
        { observe: "response", responseType: "blob" }
      )
      .pipe(
        tap((response) => {
          this.downloadService.downloadBlob(fileName, response.body);
        })
      );
  }

  constructor(
    private http: HttpClient,
    private authStore: Store<fromAuth.AuthState>,
    private downloadService: DownloadService
  ) {
    this.getCompanyId();
  }
  getCompany(): Observable<company> {
    return this.http
      .get<company>(`${environment.api_endpoint}/companies/${this.companyId}`)
      .pipe(map((company) => this.findCurrentAgreement(company)));
  }
  getDestinations(): Observable<Destination[]> {
    return this.http
      .get(`${environment.api_demographics_endpoint}/countries`)
      .pipe(
        map((countries: any) => {
          return (countries = countries
            .map((country: any) => ({
              name: country.name,
              id: country.name,
              flag: country.flag,
            }))
            .sort((d: any) => d.name));
        })
      );
  }
  findCurrentAgreement(company: company) {
    const now = moment(new Date());
    let currentActiveAgreement = company.aggrementPeriods.find((a) => {
      const start = moment(a.startDate);
      const end = moment(a.endDate);
      return now.isBetween(start, end);
    });
    company.currentAgreement = currentActiveAgreement;
    return company;
  }

  updateCompanySettings(
    defaultCurrency: string = "",
    reportsFrequency: string = "",
    reportsTiming: string = "",
    totalEmployees: string = ""
  ): Observable<company> | undefined {
    let companyParam: any;
    try {
      if (reportsFrequency != "") {
        companyParam = {
          reportsFrequency: reportsFrequency,
        };
      }
      if (reportsTiming != "") {
        companyParam = {
          reportsTiming: reportsTiming,
        };
      }
      if (defaultCurrency != "") {
        companyParam = {
          defaultCurrency: defaultCurrency,
        };
      }
      if (totalEmployees != "") {
        companyParam = {
          totalEmployees: totalEmployees,
        };
      }

      return this.http.post<company>(
        `${environment.api_endpoint}/companies/${this.companyId}/settings`,
        companyParam
      );
    } catch (error) {
      return undefined;
    }
  }

  getCompanyId(): void {
    this.authStore
      // @ts-ignore
      .pipe(select(fromAuth.getCompanyId))
      // @ts-ignore
      .subscribe((companyId: string) => (this.companyId = companyId));
  }
}

export const CompanySettingsConst = {
  totalEmployees: "totalEmployees",
  defaultCurrency: "defaultCurrency",
  reportsTiming: "reportsTiming",
  reportsFrequency: "reportsFrequency",
};
