import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, selectAll, reducer, selectEntities } from './employee.reducer';
export { State, reducer };

export const getEmployees = createFeatureSelector<State>('employees');
export const getActiveEmployees = createSelector(getEmployees, (state) =>
{
    const result = [];
    if (state && state.entities)
    {
        for (const entity of Object.values(state.entities))
        {
            result.push(entity);
        }
    }
    return result;
});

export const getAllEmployees = createSelector(getEmployees, (state) =>
{
    let result: any[] = [];
    if (state)
    {
        result = selectAll(state);
    }
    if (state.disassociatedEmployees)
    {
        for (const disassociated of state.disassociatedEmployees)
        {
            let exists = false;
            if (disassociated.idDetails)
            {
                for (const employee of result)
                {
                    if (employee.idDetails.number === disassociated.idDetails.number)
                    {
                        exists = true;
                    }
                }
            }
            else
            {
                exists = true;
            }

            if (!exists)
            {
                result.push(disassociated);
            }
        }
    }
    return result;
});
export const getSaveSucsessEmployee = createSelector(getEmployees, (state) => state.successEmployeCreated);

export const getEmployeesDictonery = createSelector(getEmployees, (state) => state ? selectEntities(state) : []);
export const getDisassociatedEmployees = createSelector(getEmployees, (state) => state ? state.disassociatedEmployees : []);
export const isLoading = createSelector(getEmployees, (state) => state.loading);
export const getEmployee = createSelector(getEmployeesDictonery, (dictionery: any, props: any) => dictionery[props.employeeId]);
