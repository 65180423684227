import { Component, OnInit, HostBinding, ApplicationRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Store, select } from '@ngrx/store';
// import * as fromReport from 'src/app/content/pages/reports/reducers';
// import { FetchYearQuota } from '../../../../pages/reports/actions/reports.actions';
import { YearQuota } from '../../../../pages/reports/models/YearQuota';
import * as fromPages from '../../../../pages/ngrx'
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
	selector: 'app-progress',
	templateUrl: './progress.component.html',
	styleUrls: ['./progress.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressComponent implements OnInit {
	// tslint:disable-next-line:max-line-length
	@HostBinding('class') classes = 'm-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width';
	constructor(private pagesStore: Store<fromPages.pagesState>, private changeDetectorRef: ChangeDetectorRef, private companyService: CompanyService) { }
	total = 0;
	value = 0;
	percentage = 0;
	isLoading = false;
	ngOnInit() {
		this.pagesStore.pipe(select(fromPages.selectProgressData)).subscribe((yearQuota: YearQuota) => {
			if (yearQuota.total < 1) {
				return;
			}
			this.total = yearQuota.total;
			this.changeDetectorRef.detectChanges();
		});
		this.isLoading = true;
		this.companyService.calcDaysUsed().subscribe({
			next: (res: any) => {
			  this.value = res.value;
			  this.percentage = (this.value / this.total);
			  this.isLoading = false;
			  this.changeDetectorRef.detectChanges();
			},
			error: (err) => {
			  this.value = 0;
			  this.percentage = (this.value / this.total);
			  this.isLoading = false;
			  this.changeDetectorRef.detectChanges();
			}
		  });
	}
}