import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as objectPath from 'object-path';
import { MenuHorizontalDirective } from '../../../../core/directives/menu-horizontal.directive';
import { ClassInitService } from '../../../../core/services/class-init.service';
import { SecondaryMenuConfigService } from 'src/app/core/services/secondary-menu-config.service';
import { SecondaryMenuHorizontalService } from 'src/app/core/services/layout/secondary-menu-horizontal.service';

@Component({
	selector: 'm-secondary-menu-horizontal',
	templateUrl: './secondary-menu-horizontal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecondaryMenuHorizontalComponent implements OnInit, AfterViewInit {
	@HostBinding('class') classes = '';
	@HostBinding('id') id = 'm_secondary_header_menu';

	// @HostBinding('attr.mMenuHorizontalOffcanvas')
	// mMenuHorOffcanvas: MenuHorizontalOffcanvasDirective;
	@HostBinding('attr.mMenuHorizontal')
	mMenuHorizontal: MenuHorizontalDirective;

	currentRouteUrl: any = '';
	activeItem: any;
	itemsWithAsides = [];

	constructor(
		private el: ElementRef,
		public classInitService: ClassInitService,
		public menuHorService: SecondaryMenuHorizontalService,
		private menuConfigService: SecondaryMenuConfigService,
		private router: Router,
	) {
		this.classes = this.menuHorService.menuClasses;
	}

	ngAfterViewInit(): void {
		Promise.resolve(null).then(() => {
			// this.mMenuHorOffcanvas = new MenuHorizontalOffcanvasDirective(this.el);
			// this.mMenuHorOffcanvas.ngAfterViewInit();

			this.mMenuHorizontal = new MenuHorizontalDirective(this.el);
			this.mMenuHorizontal.ngAfterViewInit();
		});
	}

	ngOnInit(): void {
		this.currentRouteUrl = this.router.url;
		this.menuHorService.menuList$.subscribe((menuItems: any) => this.fillAsides(menuItems));

		this.shouldOverrideAsides();

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				this.currentRouteUrl = this.router.url;
				this.shouldOverrideAsides();
			});
	}

	shouldOverrideAsides() {
	}

	fillAsides(menuItems: any) {
	}

	getActiveItemAside() {
	}

	getItemCssClasses(item: any) {
		let cssClasses = 'm-menu__item';

		if (objectPath.get(item, 'submenu')) {
			cssClasses += ' m-menu__item--submenu';
		}

		if (objectPath.get(item, 'resizer')) {
			cssClasses += ' m-menu__item--resize';
		}

		if (
			(objectPath.get(item, 'root') &&
				objectPath.get(item, 'submenu.type') === 'classic') ||
			parseInt(objectPath.get(item, 'submenu.width'), 2) > 0
		) {
			cssClasses += ' m-menu__item--rel';
		}

		const customClass = objectPath.get(item, 'custom-class');
		if (customClass) {
			cssClasses += ' ' + customClass;
		}

		if (objectPath.get(item, 'icon-only')) {
			cssClasses += ' m-menu__item--icon-only';
		}

		if (item.submenu && this.isMenuItemIsActive(item)) {
			cssClasses += ' m-menu__item--active';
		}

		return cssClasses;
	}

	getItemAttrLinkRedirect(menuItem: any): any {
		if (objectPath.get(menuItem, 'redirect')) {
			return '1';
		}

		return null;
	}

	getItemAttrResizeDesktopBreakpoint(menuItem: any) {
		if (objectPath.get(menuItem, 'resizer')) {
			return objectPath.get(menuItem, 'resize-breakpoint');
		}

		return null;
	}

	getItemAttrSubmenuToggle(menuItem: any) {
		let attrSubmenuToggle = 'hover';
		if (objectPath.get(menuItem, 'toggle') === 'click') {
			attrSubmenuToggle = 'click';
		} else if (objectPath.get(menuItem, 'submenu.type') === 'tabs') {
			attrSubmenuToggle = 'tabs';
		} else {
			// submenu toggle default to 'hover'
		}

		return attrSubmenuToggle;
	}

	getItemAttrSubmenuMode(menuItem: any) {
		return null;
	}

	getItemMenuSubmenuClass(menuItem: any) {
		let subClass = '';

		const subAlignment = objectPath.get(menuItem, 'submenu.alignment');
		if (subAlignment) {
			subClass += ' m-menu__submenu--' + subAlignment;
		}

		if (objectPath.get(menuItem, 'submenu.type') === 'classic') {
			subClass += ' m-menu__submenu--classic';
		}

		if (objectPath.get(menuItem, 'submenu.type') === 'tabs') {
			subClass += ' m-menu__submenu--tabs';
		}

		if (objectPath.get(menuItem, 'submenu.type') === 'mega') {
			if (objectPath.get(menuItem, 'submenu.width')) {
				subClass += ' m-menu__submenu--fixed';
			}
		}

		if (objectPath.get(menuItem, 'submenu.pull')) {
			subClass += ' m-menu__submenu--pull';
		}

		return subClass;
	}

	isMenuItemIsActive(item: any): boolean {
		if (item.submenu) {
			return this.isMenuRootItemIsActive(item);
		}

		if (!item.page) {
			return false;
		}

		return item.page === this.currentRouteUrl;
	}

	isMenuRootItemIsActive(item: any): boolean {
		if (item.submenu.items) {
			for (const subItem of item.submenu.items) {
				if (this.isMenuItemIsActive(subItem)) {
					return true;
				}
			}
		}

		return false;
	}
}
