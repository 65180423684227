<div class="forgotPasswordContainer">
  <h2 mat-dialog-title>Reset password</h2>
  <ng-container *ngIf="!message; else messageDisplayer">
    <mat-form-field>
      <input
        matInput
        placeholder="Email"
        name="{{ dynamicName }}"
        [formControl]="emailFormControl"
        [errorStateMatcher]="matcher"
      />
      <mat-error
        *ngIf="
          emailFormControl.hasError('email') &&
          !emailFormControl.hasError('required')
        "
      >
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Close</button>
    <button
      mat-button
      *ngIf="!message"
      (click)="emailFormControl.valid && submit()"
      cdkFocusInitial
    >
      Submit
    </button>
  </div>
  <ng-template #messageDisplayer>
    <div *ngIf="!isShowThisMessage">
      <strong>Check your email</strong>
      <br />
      We sent you an email with instructions to reset your password.
      <br />
      <br />
      If you haven't received this email within a few minutes, check your spam
      folder or make sure you entered your email correctly.
    </div>
    <div *ngIf="isShowThisMessage">
      {{ message }}
    </div>
  </ng-template>
</div>
