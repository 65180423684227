<!--begin:: Widgets/Best Sellers-->
<m-portlet>
  <!-- use ng-container[mPortletHeadTitle] for the title -->
  <ng-container mPortletHeadTitle>
    <div class="m-portlet__head-title">
      <h3 class="m-portlet__head-text">Best Sellers</h3>
    </div>
  </ng-container>

  <!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
  <ng-container mPortletHeadTools>
    <ul class="m-portlet__nav">
      <li
        class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
        m-dropdown-toggle="hover"
        aria-expanded="true"
      >
        <a href="#" class="m-portlet__nav-link m-portlet__nav-link--icon m-portlet__nav-link--icon-xl m-dropdown__toggle">
          <i class="la la-ellipsis-h m--font-brand"></i>
        </a>
        <div class="m-dropdown__wrapper" style="z-index: 101">
          <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" style="left: auto; right: 21.5px"></span>
          <div class="m-dropdown__inner">
            <div class="m-dropdown__body">
              <div class="m-dropdown__content">
                <ul class="m-nav">
                  <li class="m-nav__section m-nav__section--first">
                    <span class="m-nav__section-text">Quick Actions</span>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-share"></i>
                      <span class="m-nav__link-text">Activity</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-chat-1"></i>
                      <span class="m-nav__link-text">Messages</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-info"></i>
                      <span class="m-nav__link-text">FAQ</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                      <span class="m-nav__link-text">Support</span>
                    </a>
                  </li>
                  <li class="m-nav__separator m-nav__separator--fit"></li>
                  <li class="m-nav__item">
                    <a href="#" class="btn btn-outline-danger m-btn m-btn--pill m-btn--wide btn-sm">Cancel</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>

  <!-- use ng-container[mPortletBody] for the body content -->
  <ng-container mPortletBody>
    <!--begin::m-widget5-->
    <div class="m-widget5">
      <div class="m-widget5__item">
        <div class="m-widget5__content">
          <div class="m-widget5__pic">
            <img class="m-widget7__img" src="assets/app/media/img/products/product6.jpg" alt="" />
          </div>
          <div class="m-widget5__section">
            <h4 class="m-widget5__title">Great Logo Designn</h4>
            <span class="m-widget5__desc"> Make ginger-dashboard Great Again.Lorem Ipsum Amet </span>
            <div class="m-widget5__info">
              <span class="m-widget5__author"> Author: </span>
              <span class="m-widget5__info-label"> author: </span>
              <span class="m-widget5__info-author-name"> Fly themes </span>
              <span class="m-widget5__info-label"> Released: </span>
              <span class="m-widget5__info-date m--font-info"> 23.08.17 </span>
            </div>
          </div>
        </div>

        <div class="m-widget5__content">
          <div class="m-widget5__stats1">
            <span class="m-widget5__number">19,200</span>
            <br />
            <span class="m-widget5__sales">sales</span>
          </div>
          <div class="m-widget5__stats2">
            <span class="m-widget5__number">1046</span>
            <br />
            <span class="m-widget5__votes">votes</span>
          </div>
        </div>
      </div>
      <div class="m-widget5__item">
        <div class="m-widget5__content">
          <div class="m-widget5__pic">
            <img class="m-widget7__img" src="assets/app/media/img/products/product10.jpg" alt="" />
          </div>
          <div class="m-widget5__section">
            <h4 class="m-widget5__title">Branding Mockup</h4>
            <span class="m-widget5__desc"> Make ginger-dashboard Great Again.Lorem Ipsum Amet </span>
            <div class="m-widget5__info">
              <span class="m-widget5__author"> Author: </span>
              <span class="m-widget5__info-author m--font-info"> Fly themes </span>
              <span class="m-widget5__info-label"> Released: </span>
              <span class="m-widget5__info-date m--font-info"> 23.08.17 </span>
            </div>
          </div>
        </div>
        <div class="m-widget5__content">
          <div class="m-widget5__stats1">
            <span class="m-widget5__number">24,583</span>
            <br />
            <span class="m-widget5__sales">sales</span>
          </div>
          <div class="m-widget5__stats2">
            <span class="m-widget5__number">3809</span>
            <br />
            <span class="m-widget5__votes">votes</span>
          </div>
        </div>
      </div>
      <div class="m-widget5__item">
        <div class="m-widget5__content">
          <div class="m-widget5__pic">
            <img class="m-widget7__img" src="assets/app/media/img/products/product11.jpg" alt="" />
          </div>
          <div class="m-widget5__section">
            <h4 class="m-widget5__title">Awesome Mobile App</h4>
            <span class="m-widget5__desc"> Make ginger-dashboard Great Again.Lorem Ipsum Amet </span>
            <div class="m-widget5__info">
              <span class="m-widget5__author"> Author: </span>
              <span class="m-widget5__info-author m--font-info"> Fly themes </span>
              <span class="m-widget5__info-label"> Released: </span>
              <span class="m-widget5__info-date m--font-info"> 23.08.17 </span>
            </div>
          </div>
        </div>
        <div class="m-widget5__content">
          <div class="m-widget5__stats1">
            <span class="m-widget5__number">10,054</span>
            <br />
            <span class="m-widget5__sales">sales</span>
          </div>
          <div class="m-widget5__stats2">
            <span class="m-widget5__number">1103</span>
            <br />
            <span class="m-widget5__votes">votes</span>
          </div>
        </div>
      </div>
    </div>
    <!--end::m-widget5-->
  </ng-container>
</m-portlet>

<!--end:: Widgets/Best Sellers-->
