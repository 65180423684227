import { Injectable } from '@angular/core';

@Injectable()
export class DownloadService {
  constructor() {}

  downloadBlob(fileName: string = 'filename', resultBody: any) {
    const blob = new Blob([resultBody], { type: 'application/octet-stream' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    link.dispatchEvent(clickEvent);
  }
}
