import { Action } from '@ngrx/store';
import { currency } from './currencies.reducer';
export enum currenciesActionsTypes {
    fetchCurrenciesEnd = '[currencies] fetch currrencies end',
    setDefaultCurrency = '[currencies] set default currency'
}

export class FetchCurrenciesEnd implements Action {
    readonly type= currenciesActionsTypes.fetchCurrenciesEnd;
    constructor(public payload: currency[]) { }
}
export class setDefaultCurrency implements Action {
    readonly type= currenciesActionsTypes.setDefaultCurrency;
    constructor(public payload: string) { }
}

export type currenciesActions =  FetchCurrenciesEnd | setDefaultCurrency;