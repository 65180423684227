<div mMenuAside (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)" id="m_ver_menu" class="m-aside-menu" m-menu-vertical="1" [ngClass]="classInitService.onClassesUpdated$ | async | mGetObject:'aside_menu' | mJoin" [attr.m-menu-dropdown]="menuAsideService.isDropdown" [attr.m-menu-dropdown-timeout]="menuAsideService.dropdownTimeout" [attr.m-menu-scrollable]="menuAsideService.isScrollable">
	<ul class="m-menu__nav" [ngClass]="classInitService.onClassesUpdated$ | async | mGetObject:'aside_menu_nav' | mJoin">
		<ng-container [ngTemplateOutlet]="mMenuListing"></ng-container>
	</ul>
</div>

<ng-template #mMenuListing>
	<ng-container *ngFor="let child of menuAsideService.menuList$ | async">
		<m-menu-section *ngIf="child.section" [item]="child"></m-menu-section>
		<ng-container *ngIf="child.title" [ngTemplateOutlet]="mMenuItem" [ngTemplateOutletContext]="{ item: child, parentItem: null }"></ng-container>
	</ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
	<li class="m-menu__item" [ngClass]="{ 'm-menu__item--submenu': item.submenu, 'm-menu__item--active' : !item.submenu && isMenuItemIsActive(item), 'm-menu__item--open' : item.submenu && isMenuItemIsActive(item) }" aria-haspopup="true">

		<a *ngIf="!item.submenu" [routerLink]="item.page" class="m-menu__link m-menu__toggle" [matTooltip]="item.tooltip" matTooltipPosition="left">
			<ng-container [ngTemplateOutlet]="mMenuItemText" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
		</a>

		<a href="javascript:;" *ngIf="item.submenu" class="m-menu__link m-menu__toggle">
			<ng-container [ngTemplateOutlet]="mMenuItemText" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
		</a>

		<div *ngIf="item.submenu" class="m-menu__submenu">
			<span class="m-menu__arrow"></span>
			<ul class="m-menu__subnav">
				<ng-container *ngFor="let child of item.submenu">
					<ng-container [ngTemplateOutlet]="mMenuItem" [ngTemplateOutletContext]="{ item: child, parentItem: item }"></ng-container>
				</ng-container>
			</ul>
		</div>
	</li>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
	<i *ngIf="item.icon" class="m-menu__link-icon" [ngClass]="item.icon"></i>

	<i *ngIf="parentItem && parentItem.bullet === 'dot'" class="m-menu__link-bullet m-menu__link-bullet--dot">
		<span></span>
	</i>
	<i *ngIf="parentItem && parentItem.bullet === 'line'" class="m-menu__link-bullet m-menu__link-bullet--line">
		<span></span>
	</i>

	<ng-container *ngIf="!item.badge; else mMenuLinkBadge">

		<span class="m-menu__link-text" [translate]="item.translate">
			{{item.title}}
		</span>
	</ng-container>

	<ng-template #mMenuLinkBadge>

		<span class="m-menu__link-title">
			<span class="m-menu__link-wrap">
				<span class="m-menu__link-text" [translate]="item.translate">{{item.title}}</span>
				<span class="m-menu__link-badge">
					<span class="m-badge" [ngClass]="item.badge.type">{{item.badge.value}}</span>
				</span>
			</span>
		</span>
	</ng-template>


	<i *ngIf="item.submenu" class="m-menu__ver-arrow la la-angle-right"></i>
</ng-template>

