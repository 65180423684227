<div class="userDetails" [matMenuTriggerFor]="menu">
	<div class="column1">
		<i class="flaticon-user"></i>
	</div>
	<div class="column2">
		<div style="font-size: 13px;">
			{{loggedEmp.firstName + ' ' +loggedEmp.lastName}}
		</div>

		<div style="font-size: 11px;">{{companyName | async}}</div>
	</div>
</div>

<mat-menu #menu="matMenu">
	<a mat-menu-item routerLink="/settings">
		<mat-icon class='outline'>face</mat-icon>
		<span>Traveler's Management</span>
  </a>
  <button mat-menu-item (click)='downloadDocuments()'>
    <mat-icon mat-list-icon>cloud_download</mat-icon>
		<span>Insurance Documents</span>
  </button>
	<button mat-menu-item (click)='logout()'>
		<mat-icon>exit_to_app</mat-icon>
		<span>Logout</span>
	</button>

</mat-menu>