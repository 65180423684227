import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter} from 'rxjs/operators';
import { Router, NavigationStart } from '@angular/router';
import { SecondaryMenuConfig } from '../../config/secondary-menu';

@Injectable()
export class SecondaryMenuConfigService {
	public configModel: SecondaryMenuConfig = new SecondaryMenuConfig();
	public onMenuUpdated$: BehaviorSubject<SecondaryMenuConfig> = new BehaviorSubject(
		this.configModel
	);
	menuHasChanged: any = false;

	constructor(private router: Router) {
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				if (this.menuHasChanged) {
					this.resetModel();
				}
			});
	}

	setModel(menuModel: SecondaryMenuConfig) {
		this.configModel = Object.assign(this.configModel, menuModel);
		this.onMenuUpdated$.next(this.configModel);
		this.menuHasChanged = true;
	}

	resetModel() {
		this.configModel = new SecondaryMenuConfig();
		this.onMenuUpdated$.next(this.configModel);
		this.menuHasChanged = false;
	}
}
