import { pagesActions, pagesActionsTypes } from "./pages.actions";
import { FileSystemFileEntry } from "ngx-file-drop";

export interface Doc {

    _id?: string;
    createdAt?: Date;
    createdBy?: string;
    description?: string;
    fileType?: string;
    mimetype?: string;
    type: string;
    url?: string;
    fileEntry?: FileSystemFileEntry,
    sort?: number
}
export interface Agreement {
    _id?: string;
    startDate: Date;
    endDate: Date;
    quata: number;
    daysUsed: number;
    insurer: string;
    costPerDay: number;
    docs: Doc[];

}
export interface company {
    _id: string;
    name: string;
    __v: number;
    businessId: number;
    reportsFrequency: string;
    reportsTiming: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    claimRecipient: string;
    note:string;
    isActive: boolean;
    isPaid: boolean;
    defaultCurrency: string;
    totalEmployees: number;
   // companylogo: Companylogo;
    docs: Doc[];
    aggrementPeriods: Agreement[];
    currentAgreement?: Agreement;
   // Reports?: Report[];
    costPerDay: number;
    emailSuffix: string;
}
export interface pagesState {
    company?: company;
    destinations?:{name:string}[]
}

export const initialState: pagesState = {

}

export function reducer(
    state = initialState,
    action: pagesActions
): pagesState {
    switch (action.type) {
        case pagesActionsTypes.fetchCompanyDetailsEnd: {
            return { ...state,company:action.payload }
        }
        case pagesActionsTypes.fetchDestinationsEnd: {
            return { ...state,destinations:action.payload }
        }
       
        default: {
            return { ...state }
        }
    }
}
