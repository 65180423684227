// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [
					{
						title: 'Trips',
						root: true,
						icon: 'flaticon-paper-plane',
						page: '/trips'
					},
					{
						title: 'Expenses',
						root: true,
						icon: 'flaticon-cart',
						page: '/expenses'
					},
					{
						title: 'Dashboard',
						root: false,
						page: '/dashboard',
						icon: 'flaticon-dashboard'
					},
					{
						title: 'Insurance Reports',
						root: false,
						icon: 'flaticon-line-graph',
						page: '/reports'
					}
				]
			},
			aside: {
				self: {},
				items: [
					{
						title: 'Dashboard',
						desc: 'Some description goes here',
						root: true,
						icon: 'flaticon-line-graph',
						page: '/',
						badge: { type: 'm-badge--danger', value: '2' },
						translate: 'MENU.DASHBOARD'
					},
					{ section: 'Tools' },
					{
						title: 'Layout Builder',
						root: true,
						icon: 'flaticon-settings',
						page: '/builder'
					}
				]
			}
		};
	}
}
