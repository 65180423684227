<div class="m-subheader" [hidden]="subheaderService.disabled$ | async">
	<div class="d-flex align-items-center">
		<div class="mr-auto">
			<ng-container *ngIf="(subheaderService.breadcrumbs$ | async) as breadcrumbsRes">
				<h3 class="m-subheader__title" [ngClass]="{ 'm-subheader__title--separator': breadcrumbsRes && breadcrumbsRes.length > 0 }">
					{{breadcrumbsRes}}
				</h3>
				<ul *ngIf="breadcrumbsRes && breadcrumbsRes.length > 0" class="m-subheader__breadcrumbs m-nav m-nav--inline">
					<li class="m-nav__item m-nav__item--home">
						<a href="javascript:;" routerLink="/" class="m-nav__link m-nav__link--icon">
							<i class="m-nav__link-icon la la-home"></i>
						</a>
					</li>
					<ng-container *ngFor="let item of subheaderService.breadcrumbs$ | async">
						<li class="m-nav__separator">-</li>
						<li class="m-nav__item">
							<a href="javascript:;" [routerLink]="item.page" [queryParams]="item.queryParams" class="m-nav__link">
								<span class="m-nav__link-text" [translate]="item.translate">
									{{item.title}}
								</span>
							</a>
						</li>
					</ng-container>
				</ul>
			</ng-container>
		</div>
		<div>
			<div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover" aria-expanded="true">
				<a href="javascript:;" class="m-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
					<i class="la la-plus m--hide"></i>
					<i class="la la-ellipsis-h"></i>
				</a>
				<div class="m-dropdown__wrapper">
					<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
					<div class="m-dropdown__inner">
						<div class="m-dropdown__body">
							<div class="m-dropdown__content">
								<ul class="m-nav">
									<li class="m-nav__section m-nav__section--first m--hide">
										<span class="m-nav__section-text">Quick Actions</span>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-share"></i>
											<span class="m-nav__link-text">Activity</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a href="" class="m-nav__link">
											<i class="m-nav__link-icon flaticon-chat-1"></i>
											<span class="m-nav__link-text">Messages</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a class="m-nav__link">
											<i class="m-nav__link-icon flaticon-info"></i>
											<span class="m-nav__link-text">FAQ</span>
										</a>
									</li>
									<li class="m-nav__item">
										<a  class="m-nav__link">
											<i class="m-nav__link-icon flaticon-lifebuoy"></i>
											<span class="m-nav__link-text">Support</span>
										</a>
									</li>
									<li class="m-nav__item" >
										<a routerLink="/settings" class="m-nav__link" >
												<i class="m-nav__link-icon flaticon-cogwheel"></i>
											<span class="m-nav__link-text">Settings</span>
										</a>
									</li>
									<li class="m-nav__separator m-nav__separator--fit">
									</li>
									<li class="m-nav__item">
										<a href="javascript:;" class="btn btn-outline-danger m-btn m-btn--pill m-btn--wide btn-sm">Submit</a>
									</li>
								</ul>
								{{selectedMenu}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>