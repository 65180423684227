<div class="m-quick-sidebar__content">
  <span id="m_quick_sidebar_close" class="m-quick-sidebar__close">
    <i class="la la-close"></i>
  </span>

  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" routerLink="/messages" routerLinkActive="active">Messages</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/settings" routerLinkActive="active">Settings</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/logs" routerLinkActive="active">Logs</a>
    </li>
  </ul>
</div>
