import { HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable, Subject, from, throwError, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential, CredentialGuid } from './credential';
import { environment } from 'src/environments/environment';
import { Employee } from '@employee/employee.model';

@Injectable()
export class AuthenticationService implements AuthService
{
    API_URL = environment.api_endpoint;
    API_ENDPOINT_LOGIN = '/auth/email/captcha';
    API_ENDPOINT_LOGIN_VALIDATION = '/auth/email/validate';
    API_ENDPOINT_REFRESH = '/auth/refresh-token';
    API_ENDPOINT_REGISTER = '/register';
    API_ENDPOINT_LOGIN_AS_HR = '/auth/login-as-hr';

    notInterceptedHttpClient: HttpClient;

    public onCredentialUpdated$: Subject<AccessData>;
    setTokens(headers: HttpHeaders)
    {

    }
    constructor(
        private http: HttpClient,
        httpBackend: HttpBackend,
        private tokenStorage: TokenStorage,
        private util: UtilsService
    )
    {
        this.onCredentialUpdated$ = new Subject();
        this.notInterceptedHttpClient = new HttpClient(httpBackend);
    }

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
    public isAuthorized(): Observable<boolean>
    {
        return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
    }

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
    public getAccessToken(): Observable<string>
    {
        return this.tokenStorage.getAccessToken();
    }

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
    public getUserRoles(): Observable<any> | any
    {
        return this.tokenStorage.getUserRoles();
    }
    public getUser(): Observable<Employee> | any
    {
        return this.tokenStorage.getUser();
    }

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
    public refreshToken(): Observable<any>
    {
        return this.tokenStorage.getRefreshToken().pipe(
            switchMap((refreshToken: string) =>
            {
                return this.http.post<{ refreshToken: string, token: string }>(this.API_URL + this.API_ENDPOINT_REFRESH, { refresh_token: refreshToken });
            }),
            tap(accessData =>
            {

                this.tokenStorage.setAccessToken(accessData.token)
                this.tokenStorage.setRefreshToken(accessData.refreshToken)
            }),
            catchError(err =>
            {
                this.logout();
                return throwError(err);
            })
        );
    }

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
    public refreshShouldHappen(response: HttpErrorResponse): boolean
    {
        if (response.error && response.error.toString().toLowerCase() === 'unauthorized')
        {
            return true;
        }
        return false;
        return response.status === 401;
    }

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
    public verifyTokenRequest(url: string): boolean
    {
        return url.endsWith(this.API_ENDPOINT_REFRESH);
    }

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
    public login(credential: Credential, captcha: string): Observable<any>
    {   
        credential.email = credential.email.toLowerCase();
        credential.captcha = captcha;
        return this.http.post<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN, credential).pipe(
            map((result: any) =>
            {
                if (result instanceof Array)
                {
                    return result.pop();
                }
                //TODO: remove this remark when I remember!
                if (result.user)
                {
                    if (!result.user.companyId)
                    {
                        alert('Please contact support, company id is missing');
                        result.user = {};
                        return throwError('no company id');
                    }
                    const accessData: AccessData = {
                        accessToken: result.token,
                        refreshToken: result.refreshToken,
                        roles: [result.user.role.toUpperCase()],
                        companyId: result.user.companyId,
                        userId: result.user._id,
                        user: result.user
                    };
                    return accessData;
                }
                return undefined;
            }),
            tap(this.saveAccessData.bind(this)),
            catchError(this.handleError('login', []))
        );
    }

	/**
	 * Submit login request
	 * @param {email} email
	 * @returns {Observable<any>}
	 */
    public loginGetGuid(email: string): Observable<string | undefined>
    {   
        return this.http.post<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN + "/step1", { email }).pipe(
            map((result: any) =>
            {
                return result.guid;
            }),
            tap(this.saveAccessData.bind(this)),
            catchError(this.handleError('loginGetGuid', []))
        );
    }

	/**
	 * Submit login request
	 * @param {CredentialGuid} credential
	 * @returns {Observable<any>}
	 */
    public loginWithGuid(credential: CredentialGuid, captcha: string): Observable<any>
    {
        const credentialsToSend = {
            password: credential.password,
            captcha: captcha,
            guid: credential.guid,
          };
          
        return this.http.post<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN + "/step2", credentialsToSend).pipe(
            map((result: any) =>
            {
                if (result instanceof Array)
                {
                    return result.pop();
                }
                //TODO: remove this remark when I remember!
                if (result.user)
                {
                    if (!result.user.companyId)
                    {
                        alert('Please contact support, company id is missing');
                        result.user = {};
                        return throwError('no company id');
                    }
                    const accessData: AccessData = {
                        accessToken: result.token,
                        refreshToken: result.refreshToken,
                        roles: [result.user.role.toUpperCase()],
                        companyId: result.user.companyId,
                        userId: result.user._id,
                        user: result.user
                    };
                    return accessData;
                }
                return undefined;
            }),
            tap(this.saveAccessData.bind(this)),
            catchError(this.handleError('login', []))
        );
    }

	/**
	 * Submit code validation request
	 */
    public codeValidation(credential: { email: string, code: string }): Observable<any>
    {
        credential.email = credential.email.toLowerCase();
        return this.http.post<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN_VALIDATION, credential).pipe(
            map((result: any) =>
            {
                if (result instanceof Array)
                {
                    return result.pop();
                }

                if (result.user)
                {
                    if (!result.user.companyId)
                    {
                        alert('Please contact support, company id is missing');
                        result.user = {};
                        return throwError('no company id');
                    }
                    const accessData: AccessData = {
                        accessToken: result.token,
                        refreshToken: result.refreshToken,
                        roles: [result.user.role.toUpperCase()],
                        companyId: result.user.companyId,
                        userId: result.user._id,
                        user: result.user
                    };
                    return accessData;
                }
                return undefined;
            }),
            tap(this.saveAccessData.bind(this)),
            catchError(this.handleError('codeValidation', []))
        );
    }

    public changePassword(value: any)
    {
        if (value && !value.newPassword) value.newPassword = value.confirmPassword;
        return this.http.post(`${environment.api_endpoint}/auth/change-password`, value);
    }

    public forgotPassword(email: string)
    {
        return this.http.post(`${environment.api_endpoint}/auth/forgot-password/`, { email: email });
    }

    public resetPassword(email: string)
    {
        return this.http.post(`${environment.api_endpoint}/auth/reset-password`, { email: email });
    }

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
    private handleError<T>(operation = 'operation', result?: any)
    {
        return (error: any): Observable<any> =>
        {
            //try {
            // if (error.error)
            // 	if (error.error.message.indexOf('Change password is required') != -1)
            // 		return of('Change password is required')
            // TODO: send the error to remote logging infrastructure
            //console.error(error); // log to console instead

            // if (!error.eroror || !error.error.message) {
            // 	alert("Login failed")
            // }
            // else
            // 	alert(error.error.message)
            //	}
            //	catch (e) { }
            // Let the app keep running by returning an empty result.
            return throwError(error);
        };
    }

	/**
	 * Logout
	 */
    public logout(refresh?: boolean): void
    {
        this.tokenStorage.clear();
        if (refresh)
        {
            location.reload();
        }
    }

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
    private saveAccessData(accessData: AccessData)
    {
        if (typeof accessData !== 'undefined')
        {
            this.tokenStorage
                .setAccessToken(accessData.accessToken)
                .setRefreshToken(accessData.refreshToken)
                .setUserRoles(accessData.roles)
                // .setCompanyId(accessData.companyId)
                .setUserId(accessData.userId)
                .setUser(JSON.stringify(accessData.user));
            this.onCredentialUpdated$.next(accessData);
        }
    }

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
    public register(credential: Credential): Observable<any>
    {
        // dummy token creation
        credential = Object.assign({}, credential, {
            accessToken: 'access-token-' + Math.random(),
            refreshToken: 'access-token-' + Math.random(),
            roles: ['USER'],
        });
        return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
            .pipe(catchError(this.handleError('register', []))
            );
    }

    public loginAsHr(userId: string, companyId: string, token: string): Observable<any>
    {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${token}`);
        return this.notInterceptedHttpClient.post<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN_AS_HR, { userId, companyId }, { headers: headers }).pipe(
            map((result: any) =>
            {
                if (result.user)
                {
                    if (!result.user.companyId)
                    {
                        alert('Please contact support, company id is missing');
                        result.user = {};
                        return throwError('no company id');
                    }
                    const accessData: AccessData = {
                        accessToken: result.token,
                        refreshToken: result.refreshToken,
                        roles: [result.user.role.toUpperCase(), "ADMIN"],
                        companyId: result.user.companyId,
                        userId: result.user._id,
                        user: result.user
                    };
                    return accessData;
                }
                return undefined;
            }),
            tap<any>(this.saveAccessData.bind(this)),
            catchError(this.handleError('login', []))
        );
    }

}
