import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, EventEmitter, forwardRef, Output, OnDestroy, TemplateRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FileEntity } from './interfaces/UploadFile.interface';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { from, Observable, merge, Subscriber, Subscription, BehaviorSubject, forkJoin } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { mergeMap, tap, take, distinctUntilChanged } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { FilesUploaderService } from './files-uploader.service';
import { Employee } from '@employee/employee.model';
import { Store, select } from '@ngrx/store';
import * as fromEmployee from '@employee/index';

@Component({
  selector: 'app-files-uploader',
  templateUrl: './files-uploader.component.html',
  styleUrls: ['./files-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FilesUploaderComponent),
    },
  ],
})
export class FilesUploaderComponent implements OnInit, OnDestroy, ControlValueAccessor {
  selection = new SelectionModel(true, []);
  employee: Employee;
  showAll: boolean;
  showEmployee: boolean;
  files$: BehaviorSubject<{ [key: string]: FileEntity }> = new BehaviorSubject({});
  selectedFiles$: BehaviorSubject<FileEntity[]> | any = new BehaviorSubject([]);
  loading = false;
  _employees: { _id: string }[] = [];

  @Input() singleSelect: boolean = false;

  @Input() autoUpload: boolean = false;

  @Input() set files(files: FileEntity[]) {
    const obj: any = {};
    files.forEach((file) => (obj[file.guid] = file));
    this.files$.next(obj);
  }
  @Input() set employees(employees: { _id: string }[] | null | undefined) {
    if (!employees || employees.length === 0) return;
    this.cdr.detectChanges();

    const removeGuids = this._employees.map((e) => e._id).filter((guid) => employees.findIndex((_e) => _e._id === guid) === -1);
    this.removeEmployeeGuidFromFile(Object.values(this.files$.getValue()), removeGuids);
    this._employees = employees || [];
  }
  // @Input()
  // userSelection:TemplateRef<any>;

  get employees(): { _id: string }[] | any {
    return this._employees;
  }
  constructor(
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private employeesStore: Store<fromEmployee.State>,
    private filesUploaderService: FilesUploaderService,
    changeDetectorRef: ChangeDetectorRef
  ) {}
  get selectedFiles(): FileEntity[] {
    return this.getSelectedFiles(Object.values(this.files$.getValue()), this.selection.selected);
  }
  getSelectedFiles(files: FileEntity[], guids: string[]): FileEntity[] {
    if (guids.length === 0) return [];
    return [
      ...files.filter((file) => {
        // map the guids array to
        // array of boolean
        return (
          guids
            .map(
              (guid) =>
                // on each iteration check if the guid
                // exist in the employeesGuids property
                file.employeesGuids.indexOf(guid) > -1
            )
            // reduce the array of booleans to
            // `AND STATEMENT` of all of the output
            // from the iteration
            .reduce((a, b) => a && b)
        );
      }),
    ];
  }
  private subs: Subscription[] = [];
  ngOnInit() {
    this.subs.push(
      this.files$.subscribe((files) => this.selectedFiles$.next([...this.getSelectedFiles(Object.values(files), this.selection.selected)])),
      this.selection.changed.pipe(distinctUntilChanged()).subscribe((selected) => {
        this.selectedFiles$.next([...this.getSelectedFiles(Object.values(this.files$.getValue()), selected.source.selected)]);

        if (!this.singleSelect && selected.source.selected.length == 1) {
          this.showAll = false;
          this.subs.push(
            this.employeesStore.pipe(select(fromEmployee.getEmployee, { employeeId: selected.source.selected[0] }), take(1)).subscribe((employee) => {
              this.employee = employee;
              this.showEmployee = true;
              this.cdr.markForCheck();
            })
          );
        } else {
          this.showEmployee = false;
          this.showAll = true;
        }
      })
    );
  }
  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
  public filePerEmployee: { [key: string]: FileEntity[] } = {};

  public dropped(event: any) {
    this.loading = true;
    
    const maxNumberOfFiles = 10;
    const currentFiles = this.getSelectedFiles(Object.values(this.files$.getValue()), this.selection.selected);
    const allFilesCount = (currentFiles ? currentFiles.length : 0) + (event.files ? event.files.length : 0);
    if (allFilesCount > maxNumberOfFiles) {
      alert(`Only ${maxNumberOfFiles} files allowed`);
      this.loading = false;
      return;
    }
    merge(
      ...event
        .filter((droppedFile: any) => droppedFile.fileEntry.isFile)
        .map((droppedFile: any) => {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          return Observable.create((observer: Subscriber<File>) => {
            fileEntry.file((file: File) => {
              if (file.size > 10000000) {
                alert('File size is limited to 10 Megabyte');
                observer.error('File size is limited to 10 Megabyte');
                return;
              }
              const allowedFileTypes = ['png', 'jpeg', 'jpg', 'gif', 'pdf', 'xlsx', 'xls', 'doc', 'docx', 'txt', 'csv', 'pptx', 'ppt'];
              const fileExtantionParts = file.name.split('.');
              if (fileExtantionParts.length === 1) {
                alert('File extension is missing');
                observer.error('File extension is missing');
              }
              const fileExtantion = fileExtantionParts[fileExtantionParts.length - 1].toString().toLowerCase();
              if (allowedFileTypes.indexOf(fileExtantion) === -1) {
                alert(`File of type '${fileExtantion}' is not allowed`);
                observer.error(`File of type '${fileExtantion}' is not allowed`);
              }
              observer.next(file as File);
              observer.complete();
            });
          });
        })
    )
      .pipe(
        mergeMap((file: File | any) => {
          const uploadFile: FileEntity = {
            employeesGuids: this.selection.selected,
            name: file.name,
            file,
            uploaded: false,
            guid: Date.now() + '',
            type: file.type === 'application/pdf' ? 'pdf' : file.type.indexOf('image') > -1 ? 'image' : 'file',
          };
          this.files$.next({
            ...this.files$.getValue(),
            [uploadFile.guid]: uploadFile,
          });
          return this.startUpload(uploadFile);
        })
      )
      .subscribe(
        () => {
          this.cdr.detectChanges();
        },
        (error) => {
          this.loading = false;
          console.error(error);
          this.cdr.detectChanges();
        },
        () => {
          this.loading = false;
          this.filesChangeEvent.emit([...Object.values(this.files$.getValue())]);
        }
      );
  }
  public createDownloadUrl(file: FileEntity) {
    let href = '';
    if (file.file) {
      href = window.URL.createObjectURL(file.file);
    }
    return this.sanitizer.bypassSecurityTrustUrl(href);
  }
  public downloadFile(name: any, url: any) {
    this.loading = true;
    this.filesUploaderService
      .downloadDocument(name, url)
      .pipe(take(1))
      .subscribe(null, null, () => {
        this.loading = false;
        this.cdr.detectChanges();
      });
  }
  private uploadingList: { [key: string]: Subscription } = {};
  private startUpload(uploadFile: FileEntity) {
    if (this.autoUpload) {
      this.uploadingList[uploadFile.guid] = forkJoin(
        uploadFile.employeesGuids.map((guid) =>
          this.filesUploaderService.uploadDocument(guid, uploadFile.file!).pipe(
            tap((document: { _id: string } | any) => {
              delete this.uploadingList[uploadFile.guid];
              this.filesChangeEvent.emit([...Object.values(this.files$.getValue())]);
              this.cdr.detectChanges();
              const files: any = {
                ...this.files$.getValue(),
                [document._id]: {
                  ...uploadFile,
                  uploaded: true,
                  guid: document._id,
                },
              };
              delete files[uploadFile.guid];
              this.files$.next(files);
            })
          )
        )
      ).subscribe();
    }
    return from([uploadFile]);
  }
  public fileOver(event: any) {
    //console.log(event);
  }

  public fileLeave(event: any) {
    //console.log(event);
  }

  @ViewChild('addFiles') addFiles: any;
  onFilesAdded() {}

  private removeEmployeeGuidFromFile(files: FileEntity[], guids: string[]) {
    if (guids.length === 0 || files.length === 0) return;
    let sourceFile = Object.values(this.files$.getValue());
    files.forEach((file) => {
      guids.forEach((guid) => {
        file.employeesGuids = [...file.employeesGuids.filter((g) => g !== guid)];
      });
      if (file.employeesGuids.length === 0) {
        if (this.uploadingList[file.guid]) {
          this.uploadingList[file.guid].unsubscribe();
        } else if (this.autoUpload) {
          this.loading = true;
          this.filesUploaderService.removeDocument(this.selection.selected, file.guid).subscribe(
            () => {
              this.addFiles.nativeElement.value = '';
              this.loading = false;
              this.cdr.detectChanges();
            },
            (error) => {
              console.error(error);
            },
            () => {
              this.loading = false;
              //   console.log('finish deleting');
            }
          );
        }
      }
      sourceFile = sourceFile.map((f) => (file.guid == f.guid ? file : f)).filter((f) => f.employeesGuids.length !== 0);
    });
    this.files = [...sourceFile];
    this.filesChangeEvent.emit([...Object.values(this.files$.getValue())]);
  }
  remove(file: FileEntity) {
    this.removeEmployeeGuidFromFile([file], this.selection.selected);
  }
  @Output() filesChangeEvent: EventEmitter<FileEntity[]> = new EventEmitter();
  writeValue(files: FileEntity[]): void {
    this.files = [...files];
  }
  registerOnChange(fn: any): void {
    this.filesChangeEvent.subscribe((files) => fn(files));
  }
  registerOnTouched(fn: any): void {
    this.filesChangeEvent.subscribe((files) => fn(files));
  }
  setDisabledState?(isDisabled: boolean): void {
    // todo: disable the input;
  }
}
