// @ts-nocheck
import { Employee } from "../../../../../employee/employee.model";
import { IFlightsAndHotelModel } from "../new-trip-flights-and-hotels/new-trip-flights-and-hotels.form-group";
import { FileEntity } from "src/app/core/files-uploader/interfaces/UploadFile.interface";
import { NewTripActions, NewTripActionsTypes } from "./actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { NewTripFormStep } from "../enums/steps.enum";
import { Destination } from "../../models/destination.model";
import { INote } from "src/app/core/notes-input/note-input/note-input.interface";
import { IFlight } from "src/app/core/flight-input/flight.interface";

function assignFlightAndHotelDetail(payloadFlightsAndHotels: Partial<IFlightsAndHotelModel> = {
    flights: []
}, storeFlightsAndHotels: Partial<IFlightsAndHotelModel> = {
    flights: []
}): Partial<IFlightsAndHotelModel> {
    function assignFlightsDetail(payloadFlights: IFlight[], storeFlights: IFlight[]): IFlight[] {
        function assignFlightDetail(payloadFlight: IFlight, storeFlight: IFlight): IFlight {
            if (!(storeFlight && storeFlight.date)) {
                return payloadFlight;
            } else if (!(payloadFlight && payloadFlight.date)) {
                return storeFlight;
            } else if (storeFlight.date.toISOString().substr(0, 10) === payloadFlight.date.toISOString().substr(0, 10)) {
                return { ...storeFlight, ...payloadFlight }
            } else {
                return payloadFlight;
            }

        }
        return payloadFlights.map((f, i) => {
            return assignFlightDetail(payloadFlights[i], storeFlights[i]);
        });
    }
    return ({
        ...storeFlightsAndHotels,
        ...payloadFlightsAndHotels,
        flights: assignFlightsDetail(payloadFlightsAndHotels.flights, storeFlightsAndHotels.flights),
    })

}



export interface State extends EntityState<void> {
    loading: boolean;
    employees: Employee[],
    departureDate: Date,
    returnDate: Date,
    destination: Destination,
    details: { [key: string]: IFlightsAndHotelModel },
    files: FileEntity[],
    notes: INote[],
    step: NewTripFormStep,
    userInputedDataToNewTrip:boolean,
}

export const adapter: EntityAdapter<void> = createEntityAdapter<void>();

export const initialState: State = adapter.getInitialState({
    loading: false,
    employees: [],
    departureDate: void 0,
    returnDate: void 0,
    destinations: [],
    destination: void 0,
    details: {},
    files: [],
    notes: [],
    step: NewTripFormStep.GENERAL,
    userInputedDataToNewTrip:false
})

export function reducer(
    state = initialState,
    action: NewTripActions
): State {
    switch (action.type) {
        case (NewTripActionsTypes.SetEmployees):
            return { ...state, employees: [...action.payload] };
        case (NewTripActionsTypes.SetDepartureDate):
            return { ...state, departureDate: action.payload };
        case (NewTripActionsTypes.SetDestination):
            return { ...state, destination: action.payload };
        case (NewTripActionsTypes.SetReturnDate):
            return { ...state, returnDate: action.payload };
        case (NewTripActionsTypes.SetFiles):
            return { ...state, files: [...action.payload] };
        case NewTripActionsTypes.SetStep:
            return { ...state, step: action.payload };
        case NewTripActionsTypes.SetGeneralInformation:
            return { ...state, ...action.payload };
        case NewTripActionsTypes.ResetState: {
            return { ...initialState };
        }
        case NewTripActionsTypes.setDataChanged:{
            return { ...state,userInputedDataToNewTrip:action.payload };
        }
        case NewTripActionsTypes.SetDetails: {
            const details = {};
            action.payload.forEach((detail) => {
                details[detail.employeeId] = {
                    ...detail,
                    ...assignFlightAndHotelDetail(detail, state.details[detail.employeeId]),
                };

            })
            return { ...state, details };
        }
        case NewTripActionsTypes.SetNotes:
            return {
                ...state, notes: action.payload.map((note, i) => {
                    return {
                        ...note,
                        id: i + ''
                    }
                })
            };

    }
    return state;
}