import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ReceiptEntity } from "../files-uploader-cost/interfaces/UploadFile.interface";

@Injectable({
  providedIn: "root",
})
export class FilesSharingService {
  private filesSource = new BehaviorSubject<ReceiptEntity[]>([]);
  currentFiles = this.filesSource.asObservable();

  constructor() {}

  changeFiles(file: ReceiptEntity): void {
    //? to do add some logic, filter do not allow copies ??
    this.filesSource.next([...this.filesSource.getValue(), file]);
  }

  getAllFiles(): ReceiptEntity[] {
    return this.filesSource.getValue();
  }

  getAllFilesForEmployee(employeeId: any): ReceiptEntity[] {
    if (!employeeId || !employeeId[0]) return [];
    return this.filesSource
      .getValue()
      .filter((file) => file.employeeId[0] === employeeId[0]);
  }

  getAllFilesForCost(cost: string): ReceiptEntity[] {
    return this.filesSource.getValue().filter((file) => file.costType === cost);
  }

  getAllFilesForEmployeeForCostType(
    employeeId: string,
    costType: string
  ): ReceiptEntity[] {
    const allEmployeeFiles = this.getAllFilesForEmployee(employeeId);
    const allEmployeeFilesMatchingType = allEmployeeFiles.filter(
      (file) => file.costType === costType
    );
    return allEmployeeFilesMatchingType;
  }

  deleteFile(fileToDelete: any) {
    const filteredFiles = this.filesSource
      .getValue()
      .filter((file) => file.guid !== fileToDelete.guid);
    this.filesSource.next([...filteredFiles]);
  }

  deleteFilesByEmployee(employeeId: string) {}

  deleteAllFiles() {
    this.filesSource.next([]);
  }

  downloadFile(file: any) {}
}
