<header
  mHeader
  #mHeader
  class="m-grid__item m-header"
  [attr.m-minimize-offset]="headerService.attrMinimizeOffset"
  [attr.m-minimize-mobile-offset]="headerService.attrMinimizeMobileOffset"
>
  <div [ngClass]="headerService.containerClass" style="padding-right: 20px">
    <div class="m-stack m-stack--ver m-stack--desktop" style="overflow: hidden">
      <!-- logo -->
      <m-brand></m-brand>
      <ng-container *ngIf="loader.progress$ | async as prog">
        <mat-progress-bar class="m-loading-bar" *ngIf="prog && prog > 0" [value]="loader.progress$ | async"></mat-progress-bar>
      </ng-container>
      <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav" style="display: flex; flex-direction: row; width: 100%">
        <!-- show/hide horizontal menu based on menu.header.display option -->
        <ng-container *ngIf="headerService.menuHeaderDisplay">
          <button
            class="m-aside-header-menu-mobile-close"
            id="m_aside_header_menu_mobile_close_btn"
            [ngClass]="headerService.headerMenuCloseClass?.toString() || ''"
          >
            <i class="la la-close"></i>
          </button>
          <div style="flex: 1; justify-content: left">
            <m-menu-horizontal></m-menu-horizontal>

            <div class="header-links-container">
              <a href="https://www.gingerbit.co.il/ginger-dashboard?lang=en" target="_blank" title="Questions and Answers">Q&amp;A</a>
              <a href="https://www.youtube.com/watch?v=lfpiX84HCb0" target="_blank" title="How it works">How it works</a>
            </div>
          </div>
        </ng-container>

        <!-- DAYS USED COMPONENT -->
        <m-topbar></m-topbar>
        <div class="devider"></div>

        <a href="https://www.gingerbit.co.il/faq?lang=en" mat-icon-button matTooltip="help" style="margin-top: auto; margin-bottom: auto; margin-left: 20px">
          <mat-icon style="margin-bottom: 4px" svgIcon="help-icon"></mat-icon>
        </a>

        <div style="margin-top: 10px">
          <m-user-profile></m-user-profile>
        </div>

        <ng-container *ngIf="headerService.menuHeaderDisplay">
          <m-secondary-menu-horizontal></m-secondary-menu-horizontal>
        </ng-container>
      </div>
    </div>
  </div>
</header>
