import { Component, OnInit, Input, ViewChild, forwardRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { from, merge, Observable, Subscriber, Subscription } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilesSharingService } from '../services/files-sharing.service';
import { ReceiptEntity } from './interfaces/UploadFile.interface';
import { ReceiptsUploaderService } from './receipts-uploader.service';
import { DomSanitizer } from '@angular/platform-browser';

export declare class NgxUploadFileLocal {
  relativePath: string;
  fileEntry: NgxFileSystemEntryLocal;
  constructor(relativePath: string, fileEntry: NgxFileSystemEntryLocal);
}

export interface NgxFileSystemEntryLocal {
  name: string;
  isDirectory: boolean;
  isFile: boolean;
}

@Component({
  selector: 'app-receipts-uploader',
  templateUrl: './receipts-uploader.component.html',
  styleUrls: ['./receipts-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReceiptsUploaderComponent),
    },
  ],
})
export class ReceiptsUploaderComponent implements OnInit, OnDestroy {
  @Input() costType: string | any;
  @Input() employeeId: string | any;

  @ViewChild('addFiles') addFiles: any;

  selection = new SelectionModel(true, []);
  loading = false;
  files: ReceiptEntity[] = [];
  maxNumberOfFiles = 10;
  maxFileSize = 10000000; // 10 MegaByte
  maxFileNameLength = 20;

  private uploadingList: { [key: string]: Subscription } = {};

  constructor(
    private ReceiptsUploaderService: ReceiptsUploaderService,
    private filesSharingService: FilesSharingService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  private subs: Subscription[] = [];

  ngOnInit() {
    this.files = this.filesSharingService.getAllFilesForEmployeeForCostType(this.employeeId, this.costType);
    this.filesSharingService.currentFiles.subscribe((files) => (this.files = files));
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public dropped(event: any) {
    this.loading = true; // so user can't do anything while performing this action

    const currentSelectedFiles = this.filesSharingService.getAllFilesForEmployeeForCostType(this.employeeId, this.costType);
    const allFilesCount = (currentSelectedFiles ? currentSelectedFiles.length : 0) + (event.files ? event.files.length : 0);
    if (allFilesCount > this.maxNumberOfFiles) {
      alert(`Only ${this.maxNumberOfFiles} files allowed per category`);
      this.loading = false;
      return;
    }
    merge(
      ...event.files
        .filter((droppedFile: any) => droppedFile.fileEntry.isFile)
        .map((droppedFile: any) => {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          return Observable.create((observer: Subscriber<File>) => {
            fileEntry.file((file: File) => {
              // checks that size in allowed range
              if (file.size > this.maxFileSize) {
                alert('File size is limited to 10 Megabyte');
                observer.error('File size is limited to 10 Megabyte');
                return;
              }

              // checks that file extension exists
              const allowedFileTypes = ['png', 'jpeg', 'jpg', 'pdf'];
              const fileExtensionsParts = file.name.split('.');
              if (fileExtensionsParts.length === 1) {
                alert('File extension is missing');
                observer.error('File extension is missing');
              }

              // check that file extension is in allowed extensions
              const fileExtension = fileExtensionsParts[fileExtensionsParts.length - 1].toString().toLowerCase();
              if (allowedFileTypes.indexOf(fileExtension) === -1) {
                alert(`File of type '${fileExtension}' is not allowed`);
                observer.error(`File of type '${fileExtension}' is not allowed`);
              }

              observer.next(file as File);
              observer.complete();
            });
          });
        })
    )
      .pipe(
        mergeMap((file: File | any) => {
          const uploadFile: ReceiptEntity = {
            employeesGuids: this.selection.selected,
            name: file.name,
            file,
            uploaded: false,
            guid: Date.now() + '',
            type: file.type === 'application/pdf' ? 'pdf' : file.type.indexOf('image') > -1 ? 'image' : 'file',
            costType: this.costType,
            employeeId: this.employeeId,
          };
          return this.startUpload(uploadFile);
        })
      )
      .subscribe(
        () => {
          this.cdr.detectChanges();
        },
        (error) => {
          this.loading = false;
          console.error(error);
          this.cdr.detectChanges();
        },
        () => {
          this.loading = false;
        }
      );
  }

  public createDownloadUrl(file: ReceiptEntity) {
    let href = '';
    if (file.file) {
      href = window.URL.createObjectURL(file.file);
    }
    return this.sanitizer.bypassSecurityTrustUrl(href);
  }

  public downloadFile(name: any, url: any) {
    this.loading = true;
    this.ReceiptsUploaderService.downloadDocument(name, url)
      .pipe(take(1))
      .subscribe(null, null, () => {
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  private startUpload(uploadFile: ReceiptEntity) {
    this.putFileToService(uploadFile);
    return from([uploadFile]);
  }

  onFilesAdded() {
    const files: NgxUploadFileLocal[] = [];
    for (const droppedFile of this.addFiles.nativeElement.files) {
      const file: File = droppedFile;
      const fakeFileEntry: FileSystemFileEntry = {
        name: file.name,
        isDirectory: false,
        isFile: true,
        file: (callback: (filea: File) => void): any => {
          callback(file);
        },
      };
      const toUpload: NgxUploadFileLocal = { relativePath: fakeFileEntry.name, fileEntry: fakeFileEntry };
      files.push(toUpload);
    }
    this.dropped({ files });
  }

  private removeEmployeeGuidFromFile(files: ReceiptEntity[], guids: string[]) {}

  remove(file: ReceiptEntity) {
    this.filesSharingService.deleteFile(file);
  }

  displayNumberOfFilesSelected(): string {
    return '';
  }

  displayableFileName(fileName: string) {
    return fileName.length < this.maxFileNameLength ? fileName : fileName.substring(0, this.maxFileNameLength) + '...';
  }

  putFileToService = (file: any) => {
    this.filesSharingService.changeFiles(file);
  };

  getNumberOfFiles = (): number | string => {
    const numberOfFiles = this.filesSharingService.getAllFilesForEmployeeForCostType(this.employeeId, this.costType).length;

    return numberOfFiles > 0 ? numberOfFiles : '';
  };

  getFiles = () => {
    return this.filesSharingService.getAllFilesForEmployeeForCostType(this.employeeId, this.costType);
  };

  getAllFilesForUser = () => {
    return this.filesSharingService.getAllFilesForEmployee(this.employeeId);
  };

  getAllFilesForCostType = () => {
    return this.filesSharingService.getAllFilesForCost(this.costType);
  };

  getTextColor = () => {
    return this.getNumberOfFiles() === '' ? '#dddddd' : '#6f42c1';
  };

  getReceiptsDisplayText = () => {
    return this.getNumberOfFiles() === '' ? 'No Receipts Uploaded' : `Receipts Uploaded : ${this.getNumberOfFiles()}`;
  };
}
