import { Action } from "@ngrx/store";
import { company } from "./pages.reducer";

export enum pagesActionsTypes {
    fetchCompanyDetails = '[pages] fetch company details',
    fetchCompanyDetailsEnd = '[pages] fetch company details end',
    fetchDestinationsEnd='[pages] fetch countries end'
}

export class fetchDestinationsEnd implements Action {
    readonly type = pagesActionsTypes.fetchDestinationsEnd;
    constructor(public payload:{name:string}[]){}
}
export class fetchCompanyDetails implements Action {
    readonly type = pagesActionsTypes.fetchCompanyDetails;

}
export class fetchCompanyDetailsEnd implements Action {
    readonly type = pagesActionsTypes.fetchCompanyDetailsEnd;
    constructor(public payload:company){}

}
export type pagesActions = fetchCompanyDetails | fetchCompanyDetailsEnd| fetchDestinationsEnd;