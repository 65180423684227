<h2 mat-dialog-title>Reset password</h2>
<mat-divider></mat-divider>
<h4>{{ message }}</h4>
<mat-spinner *ngIf="loading && message == ''" style="position: absolute; top: calc(50% - 50px); left: calc(50% - 50px)"></mat-spinner>

<form [hidden]="loading" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && submit()" #formDir="ngForm">
  <mat-dialog-content class="mat-typography" style="display: flex; flex-direction: column">
    <mat-form-field>
      <input matInput placeholder="email" formControlName="email" #email [errorStateMatcher]="matcher" />
    </mat-form-field>
    <div class="errors">
      <small *ngIf="formGroup.hasError('required', ['email']) && formDir.submitted"> Required! </small>
      <small *ngIf="formGroup.hasError('email', ['email']) && formDir.submitted"> Incorrect email </small>
    </div>

    <mat-form-field>
      <mat-label>new password</mat-label>
      <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
      <input matInput #passwordWithValidation [type]="toggle.type" required [formControl]="passwordComponentWithValidation.passwordFormControl" />
      <mat-error *ngIf="passwordComponentWithValidation.passwordFormControl.hasError('required')"> Password is required </mat-error>
      <mat-error *ngIf="passwordComponentWithValidation.passwordFormControl.hasError('pattern')"> Password is not valid </mat-error>
    </mat-form-field>
    <mat-password-strength #passwordComponentWithValidation [password]="passwordWithValidation.value"> </mat-password-strength>
    <mat-password-strength-info [passwordComponent]="passwordComponentWithValidation"> </mat-password-strength-info>

    <mat-form-field>
      <mat-pass-toggle-visibility #toggleConfirm matSuffix></mat-pass-toggle-visibility>
      <input [type]="toggleConfirm.type" matInput placeholder="confirm password" autocomplete="confirm-password" formControlName="confirmPassword" />
      <mat-error *ngIf="formGroup.hasError('required', ['confirmPassword']) && formDir.submitted"> Password is required </mat-error>
    </mat-form-field>
    <div class="errors">
      <small *ngIf="!formGroup.hasError('required', ['confirmPassword']) && formGroup.hasError('mismatch') && formDir.submitted">
        The new and the confirmation passwords do not match
      </small>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button cdkFocusInitial>Update</button>
  </mat-dialog-actions>
</form>
<button *ngIf="message" mat-raised-button mat-dialog-close color="primary">close</button>
