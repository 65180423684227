import
{
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
} from '@angular/core';
import { NgForm } from '../../../../../node_modules/@angular/forms';
import { SpinnerButtonOptions } from '../../partials/content/general/spinner-button/button-options.interface';
import * as objectPath from 'object-path';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'm-polywizz',
	templateUrl: './polywizz.component.html',
	styleUrls: ['./polywizz.component.scss']
})
export class PolywizzComponent implements OnInit
{
	public model: any = { firstName: '', lastName: '', dateOfBirth: undefined, insuredId: '', idIssueDate: undefined, email: '', phoneNumber: '' };

	@ViewChild('f') f: NgForm;
	@ViewChild('firstName', { read: ElementRef }) firstNameControl: ElementRef;
	@ViewChild('lastName', { read: ElementRef }) lastNameControl: ElementRef;
	@ViewChild('dateOfBirth', { read: ElementRef }) dateOfBirthControl: ElementRef;
	@ViewChild('insuredId', { read: ElementRef }) insuredIdControl: ElementRef;
	@ViewChild('idIssueDate', { read: ElementRef }) idIssueDateControl: ElementRef;
	@ViewChild('email', { read: ElementRef }) emailControl: ElementRef;
	@ViewChild('phoneNumber', { read: ElementRef }) phoneNumberControl: ElementRef;

	errors: any = [];

	maxDate: string;
	minDate: Date;

	spinner: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		buttonColor: 'primary',
		spinnerColor: 'accent',
		fullWidth: false,
		mode: "indeterminate"
	};

	constructor(public translate: TranslateService, private cdr: ChangeDetectorRef)
	{

	}

	ngOnInit()
	{
		const date = new Date().toISOString().split("T");
		this.maxDate = date[0];
		this.minDate = new Date(1990, 0, 1);
		this.cdr.detectChanges();
	}

	submit()
	{
		this.spinner.active = true;
		if (this.validate())
		{
			const date = new Date(this.model.idIssueDate);
			const birth_date = new Date(this.model.dateOfBirth);
			let idIssueDate = '';
			let dateOfBirth = '';
			if (date)
			{
				var curr_date = ('0' + date.getDate()).slice(-2);
				var curr_month = ('0' + (date.getMonth() + 1)).slice(-2);
				var curr_year = date.getFullYear();
				idIssueDate = `${curr_year}-${curr_month}-${curr_date}`;
			}
			if (birth_date)
			{
				const curr_date_birth = ('0' + birth_date.getDate()).slice(-2);
				const curr_month_birth = ('0' + (birth_date.getMonth() + 1)).slice(-2);
				const curr_year_birth = birth_date.getFullYear();
				dateOfBirth = `${curr_year_birth}-${curr_month_birth}-${curr_date_birth}`;
			}
			
			const user = {
				firstName: this.model.firstName,
				lastName: this.model.lastName,
				phoneNumber: this.model.phoneNumber,
				email: this.model.email,
				idDetails: {
					number: this.model.insuredId
				},
				idIssuingDate: idIssueDate,
				dateOfBirth: dateOfBirth
			};

			try
			{
				fetch(`${environment.api_health_insurance_endpoint}/polywizz/get-sign-link`, {
					method: 'post',
					mode: 'cors',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(user)
				})
					.then(response =>
					{
						return response.json();
					})
					.then((response) =>
					{
						this.spinner.active = false;
						this.cdr.detectChanges();
                        window.location.assign(response.signLink);
						// const win = window.open(response.signLink, '_blank');
						// win.focus();
					})
					.catch(error =>
					{
						this.spinner.active = false;
						this.cdr.detectChanges();
					});
			}
			catch (error)
			{
				this.spinner.active = false;
				this.cdr.detectChanges();
			}
		}
	}

	validate = () =>
	{
		if (this.f.form.status === 'VALID')
		{
			return true;
		}

		for (const key in this.f.form.controls)
		{
			this.f.form.controls[key].markAsDirty();
			this.f.form.controls[key].markAsTouched();
		}

		this.errors = [];
		if (objectPath.get(this.f, 'form.controls.email.errors.email'))
		{
			this.errors.push(this.translate.instant('POLYWIZZ.VALIDATION.INVALID', { name: this.translate.instant('POLYWIZZ.INPUT.EMAIL') }));
		}
		if (objectPath.get(this.f, 'form.controls.email.errors.required'))
		{
			this.errors.push(this.translate.instant('POLYWIZZ.VALIDATION.REQUIRED', { name: this.translate.instant('POLYWIZZ.INPUT.EMAIL') }));
		}
		if (objectPath.get(this.f, 'form.controls.firstName.errors.required'))
		{
			this.errors.push(this.translate.instant('POLYWIZZ.VALIDATION.REQUIRED', { name: this.translate.instant('POLYWIZZ.INPUT.FIRST_NAME') }));
		}
		if (objectPath.get(this.f, 'form.controls.lastName.errors.required'))
		{
			this.errors.push(this.translate.instant('POLYWIZZ.VALIDATION.REQUIRED', { name: this.translate.instant('POLYWIZZ.INPUT.LAST_NAME') }));
		}
		if (objectPath.get(this.f, 'form.controls.insuredId.errors.required'))
		{
			this.errors.push(this.translate.instant('POLYWIZZ.VALIDATION.REQUIRED', { name: this.translate.instant('POLYWIZZ.INPUT.ID_NUMBER') }));
		}
		if (objectPath.get(this.f, 'form.controls.idIssueDate.errors.required'))
		{
			this.errors.push(this.translate.instant('POLYWIZZ.VALIDATION.REQUIRED', { name: this.translate.instant('POLYWIZZ.INPUT.ID_ISSUE_DATE') }));
		}
		if (objectPath.get(this.f, 'form.controls.dateOfBirth.errors.required'))
		{
			this.errors.push(this.translate.instant('POLYWIZZ.VALIDATION.REQUIRED', { name: this.translate.instant('POLYWIZZ.INPUT.DATE_OF_BIRTH') }));
		}
		if (objectPath.get(this.f, 'form.controls.phoneNumber.errors.required'))
		{
			this.errors.push(this.translate.instant('POLYWIZZ.VALIDATION.REQUIRED', { name: this.translate.instant('POLYWIZZ.INPUT.PHONE_NUMBER') }));
		}

		if (this.errors.length > 0)
		{
			this.spinner.active = false;
		}

		return false;
	}
}