<div
    style="background-image: url(assets/app/media/img/bg/bg-4.jpg);height:100%;width:100%;text-align: center;color:#ffffff;;font-family:'Roboto';font-weight:500;">

    <div class="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1 m-login__wrapper"
        style="padding: 50px;">
        <div class="m-login__body"
            style="padding: 20px; background-color: white; max-width: 600px; margin-left: auto; margin-right: auto;">

            <div class="m-login__title">
                <img alt="" style="width:150px; margin-top:7px" src="./assets/app/media/img/logos/ginger-logo.png">
            </div>

        </div>
    </div>
</div>