import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DownloadService } from '../services/download.service';
@Injectable({
  providedIn: 'root'
})
export class FilesUploaderService {
  removeDocument(guids: string[], fileId: string): Observable<any> {

    return forkJoin(guids.map(g => this.http.delete(`${environment.api_endpoint}/travelers/${g}/document/${fileId}`)));
  }

  constructor(private http: HttpClient, private downloadService: DownloadService) { }

  uploadDocument(travelerId: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('avatar', file, file.name);
    formData.append('description', file.name);
    formData.append('type', file.type);
    return this.http.post(`${environment.api_endpoint}/travelers/${travelerId}/documents`, formData, {
      headers: {
        "enctype": "multipart/form-data"
      }
    })
  }

  uploadDocumentReceipt(travelerId: string, file: File, costType: string) {
    const formData: FormData = new FormData();
    formData.append('avatar', file, file.name);
    formData.append('description', file.name);
    formData.append('type', file.type);
    formData.append('costType', costType)
    return this.http.post(`${environment.api_endpoint}/travelers/${travelerId}/documents/receipts`, formData, {
      headers: {
        "enctype": "multipart/form-data"
      }
    }).pipe(take(1)).subscribe((res: any) => {
		  // console.log(res);
	  })
  }

  downloadDocument(name: any, url: any) {
    return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(tap((response) => {
      this.downloadService.downloadBlob(name, response.body);
    }))
  }
}
