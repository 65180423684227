import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Employee } from './employee.model';

export enum EmployeeActionTypes {
  FetchEmployees = '[Employee] Fetch Employees',
  LoadEmployees = '[Employee] Load Employees',
  AddEmployee = '[Employee] Add Employee',
  UpsertEmployee = '[Employee] Upsert Employee',
  AddEmployees = '[Employee] Add Employees',
  UpsertEmployees = '[Employee] Upsert Employees',
  UpdateEmployee = '[Employee] Update Employee',
  UpdateEmployees = '[Employee] Update Employees',
  DeleteEmployee = '[Employee] Delete Employee',
  DeleteEmployees = '[Employee] Delete Employees',
  ClearEmployees = '[Employee] Clear Employees',
  RequestAddEmployee = "[Employee] Request Add Employee",
  RequestUpdateEmployee = "[Employee] Request Update Employee",
  RequestUpdateEmployeeEnd = "[Employee] Request Update Employee end",
  RequestDeleteEmployee = "[Employee] Request Delete Employee",
  EmployeeCreateSuccess='[Employee] EmployeeCreateSuccess',
  resetCreationFlag='[Employee] resetCreationFlag',
  LoaddisassociatedEmployees='[Employee] LoaddisassociatedEmployees',
  FetchdisassociatedEmployees='[Employee] FetchdisassociatedEmployees'
}
export class resetCreateEmployeeFlaf implements Action{
  readonly type = EmployeeActionTypes.resetCreationFlag;
}
export class EmployeeCreateSuccess implements Action {
  readonly type = EmployeeActionTypes.EmployeeCreateSuccess;
  constructor(public payload:boolean) {}
}
export class FetchEmployees implements Action {
  readonly type = EmployeeActionTypes.FetchEmployees;
  
}
export class LoadEmployees implements Action {
  readonly type = EmployeeActionTypes.LoadEmployees;

  constructor(public payload: Employee[] ) { }
}
export class LoaddisassociatedEmployees implements Action {
  readonly type = EmployeeActionTypes.LoaddisassociatedEmployees;

  constructor(public payload: Employee[] ) { }
}
export class FetchdisassociatedEmployees implements Action {
  readonly type = EmployeeActionTypes.FetchdisassociatedEmployees;
}

export class RequestAddEmployee implements Action {
  readonly type = EmployeeActionTypes.RequestAddEmployee;
  constructor(public payload: {
    employee: Partial<Employee>
  
  }) { }
}
export class AddEmployee implements Action {
  readonly type = EmployeeActionTypes.AddEmployee;

  constructor(public payload: { employee: Employee }) { }
}

export class UpsertEmployee implements Action {
  readonly type = EmployeeActionTypes.UpsertEmployee;

  constructor(public payload: { employee: Employee }) { }
}
export class AddEmployees implements Action {
  readonly type = EmployeeActionTypes.AddEmployees;

  constructor(public payload: { employees: Employee[] }) { }
}
export class UpsertEmployees implements Action {
  readonly type = EmployeeActionTypes.UpsertEmployees;

  constructor(public payload: { employees: Employee[] }) { }
}
export class RequestUpdateEmployee implements Action {
  readonly type = EmployeeActionTypes.RequestUpdateEmployee;

  constructor(public payload:Partial< Employee>) { }
}
export class RequestDeleteEmployee implements Action {
  readonly type = EmployeeActionTypes.RequestDeleteEmployee;
  constructor(public payload: Employee) {
  }
}
export class UpdateEmployee implements Action {
  readonly type = EmployeeActionTypes.UpdateEmployee;

  constructor(public payload: { employee: Update<Employee> }) { }
}

export class UpdateEmployees implements Action {
  readonly type = EmployeeActionTypes.UpdateEmployees;

  constructor(public payload: { employees: Update<Employee>[] }) { }
}

export class DeleteEmployee implements Action {
  readonly type = EmployeeActionTypes.DeleteEmployee;

  constructor(public payload: Employee) { }
}

export class DeleteEmployees implements Action {
  readonly type = EmployeeActionTypes.DeleteEmployees;

  constructor(public payload: { ids: string[] }) { }
}

export class ClearEmployees implements Action {
  readonly type = EmployeeActionTypes.ClearEmployees;
}

export type EmployeeActions =
FetchEmployees
  | LoadEmployees
  | RequestAddEmployee
  | AddEmployee
  | UpsertEmployee
  | AddEmployees
  | UpsertEmployees
  | RequestUpdateEmployee
  | UpdateEmployee
  | UpdateEmployees
  | DeleteEmployee
  | DeleteEmployees
  | ClearEmployees|EmployeeCreateSuccess|resetCreateEmployeeFlaf|LoaddisassociatedEmployees|FetchdisassociatedEmployees;
