import { Action } from "@ngrx/store";
import { Employee } from "../../../../../employee/employee.model";
import { FileEntity } from "src/app/core/files-uploader/interfaces/UploadFile.interface";
import { NewTripFormStep } from "../enums/steps.enum";
import { Destination } from "../../models/destination.model";
import { IFlightsAndHotelModel } from "../new-trip-flights-and-hotels/new-trip-flights-and-hotels.form-group";
import { INote } from "src/app/core/notes-input/note-input/note-input.interface";
export enum NewTripActionsTypes {
    SetEmployees = '[New Trip] set the employees',
    SetDepartureDate = "[New Trip] SetDepartureDate",
    SetReturnDate = "[New Trip] SetReturnDate",
    SetDestination = "[New Trip] SetDestination",
    SetFiles = "[New Trip] SetFiles",
    SetDetails = "[New Trip] SetDetails",
    SetStep = "[New Trip] set step of the form",
    SetGeneralInformation = "[New Trip] Set GeneralInformation",
    SetNotes = "[New Trip] SetNotes",
    ResetState = "[New Trip] Reset",
    setDataChanged= "[New Trip] set new trip data was inputed"
}
export class SetDataChanged implements Action {
    readonly type = NewTripActionsTypes.setDataChanged;
    constructor(public payload: boolean) { }
}
export class SetEmployees implements Action {
    readonly type = NewTripActionsTypes.SetEmployees;
    constructor(public payload: Employee[]) { }
}
export class SetDepartureDate implements Action {
    readonly type = NewTripActionsTypes.SetDepartureDate;
    constructor(public payload: Date) { }
}
export class SetReturnDate implements Action {
    readonly type = NewTripActionsTypes.SetReturnDate;
    constructor(public payload: Date) { }
}
export class SetDestination implements Action {
    readonly type = NewTripActionsTypes.SetDestination;
    constructor(public payload: Destination) { }
}
export class SetFiles implements Action {
    readonly type = NewTripActionsTypes.SetFiles;
    constructor(public payload: FileEntity[]) { }
}
export class SetStep implements Action {
    readonly type = NewTripActionsTypes.SetStep;
    constructor(public payload: NewTripFormStep) { }
}
export class SetGeneralInformation implements Action {
    readonly type = NewTripActionsTypes.SetGeneralInformation;
    constructor(public payload: {
        employees: Employee[],
        departureDate: Date,
        returnDate: Date,
        destination: Destination,
    }) {
    }
}

export class SetDetails implements Action {
    readonly type = NewTripActionsTypes.SetDetails;
    constructor(public payload: IFlightsAndHotelModel[]) {

    }
}

export class SetNotes implements Action {
    readonly type = NewTripActionsTypes.SetNotes;
    constructor(public payload: INote[]) {
    }
}

export class ResetState implements Action {
    readonly type = NewTripActionsTypes.ResetState;

}

export type NewTripActions =
    SetEmployees |
    SetDepartureDate |
    SetReturnDate |
    SetDestination |
    SetFiles |
    SetDetails |
    SetStep |
    SetNotes |
    ResetState |
    SetGeneralInformation |SetDataChanged;