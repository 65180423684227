<!--begin:: Widgets/Authors Profit-->
<m-portlet>
  <!-- use ng-container[mPortletHeadTitle] for the title -->
  <ng-container mPortletHeadTitle>
    <div class="m-portlet__head-title">
      <h3 class="m-portlet__head-text">Authors Profit</h3>
    </div>
  </ng-container>

  <!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
  <ng-container mPortletHeadTools>
    <ul class="m-portlet__nav">
      <li
        class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
        m-dropdown-toggle="hover"
      >
        <a href="#" class="m-portlet__nav-link m-dropdown__toggle dropdown-toggle btn btn--sm m-btn--pill btn-secondary m-btn m-btn--label-brand">
          All
        </a>
        <div class="m-dropdown__wrapper">
          <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
          <div class="m-dropdown__inner">
            <div class="m-dropdown__body">
              <div class="m-dropdown__content">
                <ul class="m-nav">
                  <li class="m-nav__section m-nav__section--first">
                    <span class="m-nav__section-text">Quick Actions</span>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-share"></i>
                      <span class="m-nav__link-text">Activity</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-chat-1"></i>
                      <span class="m-nav__link-text">Messages</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-info"></i>
                      <span class="m-nav__link-text">FAQ</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                      <span class="m-nav__link-text">Support</span>
                    </a>
                  </li>
                  <li class="m-nav__separator m-nav__separator--fit"></li>
                  <li class="m-nav__item">
                    <a href="#" class="btn btn-outline-danger m-btn m-btn--pill m-btn--wide btn-sm">Cancel</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>

  <!-- use ng-container[mPortletBody] for the body content -->
  <ng-container mPortletBody>
    <div class="m-widget4">
      <div class="m-widget4__item">
        <div class="m-widget4__img m-widget4__img--logo">
          <img src="assets/app/media/img/client-logos/logo5.png" alt="" />
        </div>
        <div class="m-widget4__info">
          <span class="m-widget4__title"> Trump Themes </span>
          <br />
          <span class="m-widget4__sub"> Make ginger-dashboard Great Again </span>
        </div>
        <span class="m-widget4__ext">
          <span class="m-widget4__number m--font-brand">+$2500</span>
        </span>
      </div>
      <div class="m-widget4__item">
        <div class="m-widget4__img m-widget4__img--logo">
          <img src="assets/app/media/img/client-logos/logo4.png" alt="" />
        </div>
        <div class="m-widget4__info">
          <span class="m-widget4__title"> StarBucks </span>
          <br />
          <span class="m-widget4__sub"> Good Coffee & Snacks </span>
        </div>
        <span class="m-widget4__ext">
          <span class="m-widget4__number m--font-brand">-$290</span>
        </span>
      </div>
      <div class="m-widget4__item">
        <div class="m-widget4__img m-widget4__img--logo">
          <img src="assets/app/media/img/client-logos/logo3.png" alt="" />
        </div>
        <div class="m-widget4__info">
          <span class="m-widget4__title"> Phyton </span>
          <br />
          <span class="m-widget4__sub"> A Programming Language </span>
        </div>
        <span class="m-widget4__ext">
          <span class="m-widget4__number m--font-brand">+$17</span>
        </span>
      </div>
      <div class="m-widget4__item">
        <div class="m-widget4__img m-widget4__img--logo">
          <img src="assets/app/media/img/client-logos/logo2.png" alt="" />
        </div>
        <div class="m-widget4__info">
          <span class="m-widget4__title"> GreenMakers </span>
          <br />
          <span class="m-widget4__sub"> Make Green Great Again </span>
        </div>
        <span class="m-widget4__ext">
          <span class="m-widget4__number m--font-brand">-$2.50</span>
        </span>
      </div>
      <div class="m-widget4__item">
        <div class="m-widget4__img m-widget4__img--logo">
          <img src="assets/app/media/img/client-logos/logo1.png" alt="" />
        </div>
        <div class="m-widget4__info">
          <span class="m-widget4__title"> FlyThemes </span>
          <br />
          <span class="m-widget4__sub"> A Let's Fly Fast Again Language </span>
        </div>
        <span class="m-widget4__ext">
          <span class="m-widget4__number m--font-brand">+$200</span>
        </span>
      </div>
    </div>
  </ng-container>
</m-portlet>
<!--end:: Widgets/Authors Profit-->
