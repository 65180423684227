import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, ControlValueAccessor } from '@angular/forms';
import { FilterAbstractComponent } from '../filter.abstract.component';

@Component({
	selector: 'app-date-filter',
	templateUrl: './date-filter.component.html',
	styleUrls: ['./date-filter.component.css'],
})
export class DateFilterComponent extends FilterAbstractComponent implements OnInit, ControlValueAccessor {

	constructor() { super(); }
	@Input()
	formControl = new FormControl();
	@Input()
	set currentDate(date: Date | null) {
		if (JSON.stringify(this.formControl.value) !== JSON.stringify(date)) {
			this.formControl.setValue(date);
		}
	}
	@Input() minDate: any = new Date(-Infinity);
	@Input() maxDate: any = new Date(+Infinity);

	@Input() formFieldClass: string | any;
	
	@Output() dateChangeEvent: EventEmitter<Date | null> = new EventEmitter<Date | null>();
	dateChange(event: any) {
		if (!event.value) {
			return;
		}
		const newDate = event.value.toDate();
		this.dateChangeEvent.emit(newDate);
		this.currentDate = newDate;
	}

	ngOnInit() {
		// TODO: fix when bored, not urgent. make sure min-max dates work properly and cant go over each others limits
	}

	writeValue(date: Date): void {
		if (!date || !(date instanceof Date)) {
			console.log('Received date is not of type Date.');
			return;
		}
		this.currentDate = date;
	}

	registerOnChange(fn: any): void {
		this.dateChangeEvent.subscribe((date) => fn(date));
	}

	registerOnTouched(fn: any): void {
		this.dateChangeEvent.subscribe((date) => fn(date));
	}

	setDisabledState?(isDisabled: boolean): void {
		// todo: disable the input;
	}

	getClasses()
	{
		let classesObj: any = {}
		classesObj[this.formFieldClass]=true;
		return classesObj;
	}
}
