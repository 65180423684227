<div style="margin-top:10px;">
  <div>
    <span translate="MENU.DAYS_USED"></span>
    <span class="pull-right" style="display: flex; align-items: center;">
      <ng-container *ngIf="isLoading; else valueDisplay">
        <mat-spinner [diameter]="12"></mat-spinner>
      </ng-container>

      <ng-template #valueDisplay>
        {{ value ? value : 0 }}
      </ng-template>
      / {{ total  }}
    </span>
      <mat-progress-bar *ngIf="!isLoading" [ngClass]='{"mat-progress-bar-red": percentage&&percentage>1}' [value]="percentage * 100"></mat-progress-bar>
      <span *ngIf="!isLoading" class="percent" [style.width]="(percentage * 100) + '%'">{{!percentage?0:  (percentage | percent)}}</span>
    <app-expiration-date></app-expiration-date>
  </div>
</div>