import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ExpenseReportState } from './expense-report.reducer';
import * as _ from 'lodash';

export { reducer, initialState, ExpenseReportState } from './expense-report.reducer';

export const state = createFeatureSelector<ExpenseReportState>('expenseReports');

export const getExpenseReports = createSelector(state, (currentState) => currentState.expenseReports);
export const getComments = createSelector(state, (currentState) => currentState.selectedExpenseReport ? currentState.selectedExpenseReport.comments : undefined);
export const getApprovals = createSelector(state, (currentState) => currentState.selectedExpenseReport ? currentState.selectedExpenseReport.approvals : undefined);
export const getSelectedExpenseReport = createSelector(state, (currentState) => currentState.selectedExpenseReport);
export const getExpenses = createSelector(state, (currentState) => currentState.expenses);