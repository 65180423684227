import { pagesState, initialState, reducer } from './pages.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { YearQuota } from '../reports/models/YearQuota';
export { reducer, pagesState };


export const selectPagesState = createFeatureSelector<pagesState>('pages');

// export const defaultCurrencyCode = (state: currenciesState) => state.companysDefaultCurrencyCode;

export const selectDaysUsed = createSelector(
    selectPagesState,
    (state => state.company ? state.company.currentAgreement ? state.company.currentAgreement.daysUsed : 0 : 0)
);
export const getCompany = createSelector(
  selectPagesState,
  (state => state.company)
);
export const selectCompanyName = createSelector(
    selectPagesState,
    (state => {return state.company ? state.company.name :''})
);
export const selectExpireDate = createSelector(
    selectPagesState,
    (state => state.company ? state.company.currentAgreement ? state.company.currentAgreement.endDate : null : null)
);
export const selectQuata = createSelector(
    selectPagesState,
    (state => state.company ? state.company.currentAgreement ? state.company.currentAgreement.quata : 0 : 0)
);
export const selectDestinations = createSelector(
    selectPagesState,
    (state => state.destinations )
);
export const selectProgressData = createSelector(
    selectDaysUsed,
    selectExpireDate,
    selectQuata,
    ((value: any, expirationDate: any, total: any) => {
        let ret: YearQuota = { value, expirationDate: expirationDate, total: total }
        return ret;
    })
);