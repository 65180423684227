<mde-popover #appPopover="mdePopover">
  <div class="container mat-elevation-z8" (click)="$event.stopPropagation()">
    <mat-form-field class="full-width" *ngIf="searchEnable">
      <input autocomplete="new-password" name='{{dynamicName}}' #search    matInput (input)="onSearch(search.value)" placeholder="Search" />
      <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="onSearch(search.value = '')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" (click)="$event.stopPropagation()">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? null : null" [checked]="selection.hasValue() && true"
              [indeterminate]="selection.hasValue() && false">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of _displayedColumns" [matColumnDef]="column">
          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
          <td mat-cell *matCellDef="let element"
          [matTooltip]="element.disassociated ? 'User deleted!' : ''"   [style.color]='element.disassociated?"red":"black"'> {{element[column]}} </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsWithSelect;" (click)="selection.toggle(row)" style="cursor: pointer">
        </tr>

      </table>

      <div class="mat-table__message" *ngIf="!(dataSource.filteredData.length > 0)">
        <ng-container [ngSwitch]="noRecordsFound">
          <ng-container *ngSwitchCase="'none'">
            No records found
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="button-row" *ngIf="!autoSelect">
      <button *ngIf='_multiSelection && selection.selected.length !== this._source.length' mat-raised-button color="accent" (click)="toggleAll()">Select All</button>
      <button *ngIf='!_multiSelection || selection.selected.length === this._source.length' mat-raised-button (click)="selection.clear()">Clear</button>
      <button mat-raised-button color="accent" *ngIf='showCreateNew' (click)="add(search.nativeElement.value)">Create New</button>
      <button *ngIf="!autoSelect" mat-raised-button (click)="apply()" color="primary">Apply</button>
    </div>
  </div>

</mde-popover>
<mat-form-field (click)="open()" [ngClass]="getClasses()">
  <input autocomplete="new-password" name='{{dynamicName}}'   [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" [formControl]='inputControl' matInput
    [placeholder]="label" [errorStateMatcher]="matcher" />
  <mat-error *ngIf="formControl.hasError('required')">
    {{label}} is <strong>required</strong>
  </mat-error>
</mat-form-field>