import { YesNoDialogComponent } from './yes-no-dialog.component';
import { AbstractDialogArgs } from '../../dialogs.args.abstract';

export class YesNoDialogArgs extends AbstractDialogArgs<boolean, IYesNoDialogInput> {
    public readonly component = YesNoDialogComponent;
    constructor(
        data: IYesNoDialogInput,
        public override callback: (result: boolean) => void
    ) {
        super(data, callback);
    }
}

export interface IYesNoDialogInput {
    header: string;
    body: string;
    yesText?: string;
    noText?: string | boolean
}
