import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ForgotPasswordComponent } from './content/pages/auth/forgot-password/forgot-password.component';
import { PolywizzComponent } from './content/pages/polywizz/polywizz.component';
import { PolywizzComponent2 } from './content/pages/polywizz-2/polywizz-2.component';
import { AdminAsHrComponent } from './content/pages/admin-as-hr/admin-as-hr.component';

const routes: Routes = [
	
	{
		path: 'login',
		loadChildren: () => import("./content/pages/auth/auth.module").then(m => m.AuthModule),
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent
	},
	{
		path: 'polywizz',
		component: PolywizzComponent
    },
    {
		path: 'meeting',
		component: PolywizzComponent2
    },
    {
		path: 'admin-as-hr',
		component: AdminAsHrComponent
    },
	{
		path: '',
		loadChildren: () => import("./content/pages/pages.module").then(m => m.PagesModule),
		canLoad: [NgxPermissionsGuard],
		data: {
			permissions: {
				only: ["HR"],
				redirectTo: 'login'
			}
		},
	},
	{
		path: '**',
		redirectTo: '404',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule {}
