import { Component, OnInit, OnDestroy, } from '@angular/core';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { Router } from '@angular/router';
import { AuthNoticeService } from '../../../core/auth/auth-notice.service';

@Component({
    selector: 'm-admin-as-hr',
    templateUrl: './admin-as-hr.component.html',
    styleUrls: ['./admin-as-hr.component.scss']
})
export class AdminAsHrComponent implements OnInit, OnDestroy
{
    userId;
    companyId;
    token;

    constructor(private authService: AuthenticationService,
        public authNoticeService: AuthNoticeService,
        private router: Router)
    {
        const params = new URLSearchParams(location.search);
        this.userId = params.get('userId');
        this.companyId = params.get('companyId');
        this.token = params.get('token');
    }

    ngOnInit()
    {
        this.authService.loginAsHr(this.userId!, this.companyId!, this.token!).subscribe(response =>
        {
            if (response && response.user)
            {
                this.router.navigate(['/']);
            }
        }, (e) =>
            {
                let message = 'Invalid login';
                if (e.error.errors && e.error.errors.length > 0)
                {
                    message = e.error.errors[0].msg;
                }
                else if (e.error.message)
                {
                    message = e.error.message;
                }
                this.authNoticeService.setNotice(message, 'error');
            });
    }

    ngOnDestroy(): void
    {
        this.authNoticeService.setNotice("");
    }
}