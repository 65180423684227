import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { SetGeneralInformation } from '@trip/new-trip/redux/actions';
import { take } from 'rxjs/operators';
import { Employee } from 'src/app/employee/employee.model';
import * as fromNewTrip from '../../content/pages/trips/new-trip/redux';

@Component({
  selector: 'app-users-selections',
  templateUrl: './users-selections.component.html',
  styleUrls: ['./users-selections.component.css'],
})
export class UsersSelectionsComponent implements OnInit {
  formGroup: FormGroup = this.fb.group({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
  });

  isShowEditEmployee: boolean = false;
  bothFieldsRequiredText = 'Fill both required fields (English only)';
  isShowBothFieldsRequired = false;

  constructor(public fb: FormBuilder, private newTripStore: Store<fromNewTrip.State>) {}
  ngOnInit() {
    this.setGeneralSelectionValid();
  }
  @Input() singleSelect: boolean = false;
  @Input() selection: any;
  @Input() cdrUpdate: () => void;
  _employees: Employee[] = [];
  @Input() set employees(employees: Employee[] | null | undefined) {
    if (!employees || employees.length === 0) return;
    this._employees = employees;
    this.selectAllEmployees();
  }
  @Input() isFlightsAndCosts: boolean = false; // needed for flight addition for collinson validation

  @Output() isEmployeesSelectionValidEvent = new EventEmitter<boolean>();

  get employees(): Employee[] {
    return this._employees;
  }

  selectAllEmployees() {
    this.selection.clear();
    this.setGeneralSelectionValid();
    if (this.singleSelect) {
      this.selection.select(this.employees[0]._id);
    } else {
      this.selection.select(...this.employees.map((e) => e._id));
      if (this.cdrUpdate) this.cdrUpdate();
    }
  }

  selectEmployee(employee: Employee) {
    // whenever another employee is clicked close edit
    if (!this.isCurrentEmployeeClicked(employee)) {
      this.setIsShowEditEmployee(false);
    }
    this.setCurrentEmployeeSelectionValid(employee);
    this.selection.clear();
    this.selection.select(employee._id);
  }

  handleEditButtonClick(employee: any): void {
    this.setIsShowBothFieldsRequired(false);
    if (this.isCurrentEmployeeClicked(employee)) {
      this.setIsShowEditEmployee(!this.isShowEditEmployee);
      return;
    }
    this.selectEmployee(employee);
    this.setIsShowEditEmployee(true);
  }

  isCurrentEmployeeClicked(employee: Employee): boolean {
    const isCurrentEmployeeClickedValue = this.selection._selected.length === 1 && employee._id == this.selection._selected[0];
    return isCurrentEmployeeClickedValue;
  }

  isShouldDisplayEditForEmployee(employee: Employee): boolean {
    if (!this.isShowEditEmployee) return false;
    const selectedEmployees = this.selection._selected;
    if (!selectedEmployees) return false;
    return selectedEmployees.length === 1 && selectedEmployees[0] === employee._id;
  }

  areEmployeesInEnglishOnly(): boolean {
    // applies only to flights & costs page
    if (!this.isFlightsAndCosts) return true;

    for (const employee of this._employees) {
      if (!this.isEnglishOnly(employee.firstName) || !this.isEnglishOnly(employee.lastName)) return false;
    }
    return true;
  }

  // checks if current selected employees (or employee) are valid for collinson vouchers
  setGeneralSelectionValid(): void {
    // applies only to flights & costs page
    if (this.isFlightsAndCosts) {
      for (const employee of this._employees) {
        if (!this.isValueValid(employee.firstName) || !this.isValueValid(employee.lastName)) {
          this.isEmployeesSelectionValidEvent.emit(false);
          return;
        }
      }

      this.isEmployeesSelectionValidEvent.emit(true);
      return;
    }
  }

  setCurrentEmployeeSelectionValid(employee: Employee): void {
    // applies only to flights & costs page
    if (this.isFlightsAndCosts) {
      const isValidSomething = !this.isValueValid(employee.firstName) || !this.isValueValid(employee.lastName);
      if (!this.isValueValid(employee.firstName) || !this.isValueValid(employee.lastName)) {
        this.isEmployeesSelectionValidEvent.emit(false);
        return;
      }

      this.isEmployeesSelectionValidEvent.emit(true);
      return;
    }
  }

  isValueValid(value: string): boolean {
    return this.isEnglishOnly(value) && !this.isOnlySpaces(value);
  }

  isEnglishOnly(value: string): boolean {
    return /^[A-Za-z ]*$/.test(value);
  }

  isOnlySpaces(value: string): boolean {
    return value.trim().length === 0;
  }

  //* not used but might be in the future, makes sure the value is English only and does not have any extra spaces
  // removes all non English characters & extra spaces
  filterValue(input: string): string {
    if (!input) return '';
    // clear all non English | space characters
    input = input.replace(/[^A-Za-z ]/gi, '');

    if (!input) return '';
    // split all words by spaces, then clear all of spaces and add spaces in filtered input
    const splittedWords = input.split(' ');
    return splittedWords.filter((value) => value !== '').join(' ');
  }

  handleCancelButtonClick() {
    this.setIsShowBothFieldsRequired(false);
    this.setIsShowEditEmployee(false);
  }

  async handleSaveButtonClick(firstName: any, lastName: any, index: any): Promise<void> {
    const isNamesInEnglish = this.isEnglishOnly(firstName) && this.isEnglishOnly(lastName);
    const isOnlySpacesVal = this.isOnlySpaces(firstName) && this.isEnglishOnly(lastName);

    if (isOnlySpacesVal || !isNamesInEnglish) {
      this.setIsShowBothFieldsRequired(true);
      return;
    }

    const currentGeneral = await this.newTripStore.pipe(select(fromNewTrip.getGeneralInformation), take(1)).toPromise();
    const updatedEmployees = [...currentGeneral!.employees];
    updatedEmployees[index] = {
      ...updatedEmployees[index],
      firstName,
      lastName,
    };

    const updatedGeneral = {
      ...currentGeneral,
      employees: updatedEmployees,
    };

    // @ts-ignore
    this.newTripStore.dispatch(new SetGeneralInformation(updatedGeneral));

    this.setGeneralSelectionValid();
    this.setIsShowBothFieldsRequired(false);
    this.setIsShowEditEmployee(false);
  }

  setIsShowEditEmployee(isShow: boolean) {
    this.isShowEditEmployee = isShow;
  }

  setIsShowBothFieldsRequired(isShow: boolean) {
    this.isShowBothFieldsRequired = isShow;
  }
}
