<div class="row background-container">
  <app-users-selections
    *ngIf="employees.length"
    [singleSelect]="singleSelect"
    [selection]="selection"
    [employees]="employees"
    class="side col-md-3"
  ></app-users-selections>

  <div [ngClass]="{ files: true, 'col-md-12': employees.length === 1 && showEmployee === false, 'col-md-9': employees.length > 1 || showEmployee }">
    <mat-list>
      <mat-list-item *ngFor="let file of selectedFiles$ | async">
        <mat-icon mat-list-icon [ngSwitch]="file.type">
          <ng-container *ngSwitchCase="'image'"> photo_size_select_actual </ng-container>
          <ng-container *ngSwitchCase="'pdf'"> picture_as_pdf </ng-container>
          <ng-container *ngSwitchCase="'file'"> insert_drive_file </ng-container>
        </mat-icon>
        <h4 mat-line>{{ file.name }}</h4>
        <p mat-line>
          <mat-progress-bar *ngIf="autoUpload && !file.uploaded" mode="indeterminate"></mat-progress-bar>
        </p>
        <button mat-icon-button *ngIf="!autoUpload || file.uploaded">
          <a target="_blank" *ngIf="file.file" [attr.download]="file.name" [attr.href]="createDownloadUrl(file)">
            <mat-icon mat-list-icon>cloud_download</mat-icon>
          </a>
          <a target="_blank" *ngIf="file.downloadUrl" (click)="downloadFile(file.name, file.downloadUrl)">
            <mat-icon mat-list-icon>cloud_download</mat-icon>
          </a>
        </button>
        <button class="cancel" (click)="remove(file)" mat-icon-button>
          <mat-icon mat-list-icon>cancel</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>

    <div class="column file-upload-container">
      <ng-container>
        <div class="column">
          <p class="infoText" *ngIf="!showEmployee">Upload all trip related documents for all employees</p>
          <p class="infoText" *ngIf="showEmployee">Upload all trip related documents for {{ employee ? employee.firstName + ' ' + employee.lastName : '' }}</p>
          <p style="margin: 10px 0 0 0; font-family: 'Roboto'; font-size: 18px; font-weight: 500">Insurance policy is sent by default to all travelers</p>
          <p style="margin: 5px 0 0 0; font-family: 'Roboto'; font-size: 16px; font-weight: normal">
            Upload documents such as hotel reservations, car rental confirmations, conference itinerary etc.
          </p>
          <p></p>
        </div>
      </ng-container>

      <ngx-file-drop class="no_files" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div>
            <span>
              <button mat-button (click)="openFileSelector()" style="font-family: 'Roboto'; font-size: 16px; font-weight: bold; color: #6f42c1">Upload</button>
            </span>
            <span style="font-family: 'Roboto'; font-size: 16px; font-weight: normal; padding: 0 16px 0 0">Or Drag & Drop your files here</span>
          </div>
          <input
            #fileInput
            type="file"
            #addFiles
            (change)="onFilesAdded()"
            style="display: none"
            accept=".jpg,.jpeg,.png,.gif,.pdf,.xlsx,.xls,.doc,.docx,.txt,.pptx,.ppt"
            multiple
          />

        </ng-template>
      </ngx-file-drop>
    </div>
  </div>
  <div *ngIf="loading" class="spinner-background col-sm-12">
    <mat-spinner></mat-spinner>
  </div>
</div>