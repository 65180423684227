import { Employee } from '@employee/employee.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EmployeeActions, EmployeeActionTypes } from './employee.actions';

export interface State extends EntityState<Employee> {
  loading: boolean
  successEmployeCreated?: boolean,
  disassociatedEmployees?:Partial<Employee>[]
}

export const adapter: EntityAdapter<Employee> = createEntityAdapter<Employee>({ selectId: employee => employee._id });

export const initialState: State | any = adapter.getInitialState({
  successEmployeCreated:null,
  loading: false
});

export function reducer(

  state = initialState,
  action: EmployeeActions
): State {
  switch (action.type) {
    //case EmployeeActionTypes.RequestAddEmployee:
    case EmployeeActionTypes.FetchEmployees: {
      return { ...state, loading: true };
    }
    case EmployeeActionTypes.resetCreationFlag: {
      return { ...state, successEmployeCreated: null }
    }
    case EmployeeActionTypes.AddEmployee: {
      return adapter.addOne(action.payload.employee, { ...state, loading: false });
    }

    case EmployeeActionTypes.AddEmployee: {
      return adapter.addOne(action.payload.employee, { ...state, loading: false });
    }

    case EmployeeActionTypes.EmployeeCreateSuccess: {
      return { ...state, successEmployeCreated: action.payload }
    }
    case EmployeeActionTypes.UpsertEmployee: {
      return adapter.upsertOne(action.payload.employee, state);
    }

    case EmployeeActionTypes.AddEmployees: {
      return adapter.addMany(action.payload.employees, state);
    }

    case EmployeeActionTypes.UpsertEmployees: {
      return adapter.upsertMany(action.payload.employees, state);
    }

    case EmployeeActionTypes.UpdateEmployee: {
      return adapter.updateOne(action.payload.employee, state);
    }

    case EmployeeActionTypes.UpdateEmployees: {
      return adapter.updateMany(action.payload.employees, state);
    }

    case EmployeeActionTypes.DeleteEmployee: {
      return adapter.removeOne(action.payload._id, state);
    }

    case EmployeeActionTypes.DeleteEmployees: {
      return adapter.removeMany(action.payload.ids, state);
    }
    case EmployeeActionTypes.LoaddisassociatedEmployees: {

      return {...state,disassociatedEmployees:action.payload}
    }
    case EmployeeActionTypes.LoadEmployees: {
      return adapter.setAll(action.payload, state);
    }

    case EmployeeActionTypes.ClearEmployees: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
