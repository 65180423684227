import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractDialogArgs } from './dialogs.args.abstract';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {
  constructor(private dialog: MatDialog) {

  }
  private currentDialogClosed: Subscription;
  private dialogStack: AbstractDialogArgs[] = [];
  currentDialog:MatDialogRef<any>;
  openDialog<R>(dialogArgs: AbstractDialogArgs<R> | any) {
    if (this.currentDialogClosed) {
      this.currentDialogClosed.unsubscribe();
    }
    if (this.dialogStack.length > 0) {
      this.dialog.closeAll();
    }
    this.dialogStack = [...this.dialogStack, dialogArgs];
    this.currentDialog=this.dialog.open<any, any, R>(
      dialogArgs.component,
      {
        ...dialogArgs.dialogOptions,
        data: dialogArgs.data
      })
    this.currentDialogClosed = this.currentDialog.afterClosed().subscribe((args: R) => {
        if (dialogArgs.callback) {
          dialogArgs.callback(args);
        }
        this.dialogStack.pop();
        if (this.dialogStack.length > 0) {
          this.openDialog(this.dialogStack.pop());
        }
      });
  }
  close(data?:any){
    this.currentDialog.close(data);
  }
  closeAll() {
    this.dialog.closeAll();
  }
  updateDialogWithError(errorMessage: string) {
    if (this.currentDialog) {
      this.currentDialog.componentInstance.error = errorMessage;
    }
  }

}
