import { Component, OnInit, HostBinding, ApplicationRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Store, select } from '@ngrx/store';
// import * as fromReport from 'src/app/content/pages/reports/reducers';
// import { FetchYearQuota } from '../../../../pages/reports/actions/reports.actions';
import { YearQuota } from '../../../../pages/reports/models/YearQuota';
import * as fromPages from '../../../../pages/ngrx'

@Component({
	selector: 'app-expiration-date',
	templateUrl: './expiration-date.component.html',
	styleUrls: ['./expiration-date.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpirationDateComponent implements OnInit {
	// tslint:disable-next-line:max-line-length
	@HostBinding('class') classes = 'm-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width';
	expirationDate: Observable<Date | null>;
	constructor(private pagesStore: Store<fromPages.pagesState> , private changeDetectorRef: ChangeDetectorRef) { }
	ngOnInit() {
		this.expirationDate=this.pagesStore.pipe(select(fromPages.selectExpireDate));
	}
}
