import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthenticationService } from '../../../../core/auth/authentication.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ForgotPasswordDialogComponent implements OnInit
{
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  isShowThisMessage = false;

  message = "";
  dynamicName = new Date().getTime();
  matcher = new MyErrorStateMatcher();

  constructor(private authService: AuthenticationService, private cdr: ChangeDetectorRef) { }

  submit() {
    this.authService.forgotPassword(this.emailFormControl.value).subscribe((d: { message: string } | any) => {
      this.message = "should show message"; // need to change it to something or screen won't update
      this.cdr.detectChanges();
    }, (e) => {
      this.isShowThisMessage = true;
      this.message = e.message || e.error.message;
      if(!this.message) {
        this.message = "Something went wrong";
      }
      this.cdr.detectChanges();
    });
  }

  ngOnInit() {
  }
}