import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {
  transform(from: Date | null | undefined, to: Date | null | undefined): any {
    if (!from || !to) return '';
  
    const momentFrom = moment(from);
    const momentTo = moment(to);
  
    const range = momentFrom.format("MMM D") + " - " + momentTo.format("MMM D, YYYY");
    return range;
  }
}
