import { Action } from '@ngrx/store';
import { Comment, Approval, ExpenseReport } from '../models/expenseReport';
import { Expense } from '@trip/models/expense.model';

export enum ExpenseReportActionsTypes {
    updateComments = 'expense-reports-update-comments',
	updateApprovals = 'expense-reports-update-approvals',
	setExpenseReports = 'set-expense-reports',
	updateExpenseReport = 'update-expense-report',
	setSelectedExpenseReport = 'set-selected-expense-report',
	updateExpenseReports = 'update-expense-reports',
	setExpenses = 'set-expenses',
    deleteExpenseReport = 'delete-expense-report'
}

export class UpdateComments implements Action {
	readonly type = ExpenseReportActionsTypes.updateComments;
	constructor(public payload: { comments: Comment[], expenseReport: ExpenseReport }) { }
}

export class UpdateApprovals implements Action {
	readonly type = ExpenseReportActionsTypes.updateApprovals;
	constructor(public payload: { approvals: Approval[], expenseReport: ExpenseReport }) { }
}

export class SetExpenseReports implements Action {
	readonly type = ExpenseReportActionsTypes.setExpenseReports;
	constructor(public payload: ExpenseReport[]) { }
}

export class SetSelectedExpenseReport implements Action {
	readonly type = ExpenseReportActionsTypes.setSelectedExpenseReport;
	constructor(public payload: ExpenseReport) { }
}

export class SetExpenses implements Action {
	readonly type = ExpenseReportActionsTypes.setExpenses;
	constructor(public payload: Expense[]) { }
}

export class UpdateExpenseReport implements Action {
	readonly type = ExpenseReportActionsTypes.updateExpenseReport;
	constructor(public payload: ExpenseReport) { }
}

export class UpdateExpenseReports implements Action {
	readonly type = ExpenseReportActionsTypes.updateExpenseReports;
	constructor() { }
}

export class DeleteExpenseReport implements Action {
	readonly type = ExpenseReportActionsTypes.deleteExpenseReport;
	constructor(public payload: ExpenseReport) { }
}

export type ExpenseReportActions = UpdateComments | UpdateApprovals | SetExpenseReports
	| UpdateExpenseReport | SetSelectedExpenseReport | UpdateExpenseReports | SetExpenses | DeleteExpenseReport;