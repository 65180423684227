import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Employee } from '@employee/employee.model';

@Injectable()
export class TokenStorage {
	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	rememberMe: boolean = false;

	/**
	 *
	 */
	constructor() {
		if (localStorage.getItem("accessToken"))
			this.rememberMe = true;
	}

	public getAccessToken(): Observable<string> {
		const token: string = this.getFromStorage("accessToken");
		return of(token);
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = this.getFromStorage('refreshToken');
		return of(token);
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> | undefined {
		const roles: any = this.getFromStorage('userRoles');
		try {
			return of(JSON.parse(roles));
		} catch (e) { return undefined; }
	}

	public getUser(): Observable<Employee> | Observable<null> {
		const user: any = this.getFromStorage('user');
		try {
			return of(JSON.parse(user));
		} catch (e) { return of(null) }
	}
	
	/**
	 * Get user company id in JSON string
	 * @returns string
	 */
	public getCompanyIdDirect(): string | undefined {
		const companyId: any = this.getFromStorage('companyId');
		try {
			return JSON.parse(companyId);
		} catch (e) { return undefined; }
	}

	/**
 * Get user company id in JSON string
 * @returns {Observable<string>}
 */
	public getUserId(): Observable<string> | Observable<undefined> | undefined {
		const userId: any = this.getFromStorage('userId');
		try {
			return of(JSON.parse(userId));
		} catch (e) { return undefined; }
	}
	/**
	 * Get user company id in JSON string
	 * @returns string
	 */
	public getUserIdDirect(): string | undefined {
		const userId: any = this.getFromStorage('userId');
		try {
			return JSON.parse(userId);
		} catch (e) { return undefined; }
	}
	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		this.setStorageKey('accessToken', token);

		return this;
	}

	public setUser(token: string): TokenStorage {
		this.setStorageKey('user', token);
		return this;
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(token: string): TokenStorage {
		this.setStorageKey('refreshToken', token);

		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		if (roles != null) {
			this.setStorageKey('userRoles', JSON.stringify(roles));
		}

		return this;
	}

	/**
 	* Set user company id
 	* @param companyId
 	* @returns {TokenStorage}
 	*/
	public setUserId(userId: string): any {
		if (userId != null) {
			this.setStorageKey('userId', JSON.stringify(userId));
		}
		return this;
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.clear();
		sessionStorage.clear();
	}

	public setStorageKey(key: any, value: any): any {
		if (!this.rememberMe) {
			sessionStorage.setItem(key, value);
		}
		else {
			localStorage.setItem(key, value);
		}
	}
	public getFromStorage(key: any): any {
		if (!this.rememberMe) {
			return sessionStorage.getItem(key);
		}
		else {
			return localStorage.getItem(key);
		}
	}
}
