import { currenciesActions, currenciesActionsTypes } from './currencies.actions';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

export interface currency {
	currencyCode: string;
	name: string;
	rate: number;
	symbol: string;
	updateDate: Date;
};

export interface currenciesState extends EntityState<currency> {
	companysDefaultCurrencyCode?:string;
};

export const adapter: EntityAdapter<currency> = createEntityAdapter<currency>({ selectId: currency => currency.currencyCode });

export const initialState: currenciesState = adapter.getInitialState();

export function reducer(
	state = initialState,
	action: currenciesActions
): currenciesState {
	switch (action.type) {
		case currenciesActionsTypes.fetchCurrenciesEnd: {
			return adapter.addMany(action.payload, state);
		}
		case currenciesActionsTypes.setDefaultCurrency: {
			return {...state,companysDefaultCurrencyCode:action.payload}
		}
		default: {
			return {...state};
		}
	}
}
export const {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
} = adapter.getSelectors();