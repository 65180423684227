<mat-list [style.display]="employees.length > 0 && employees[0].firstName ? 'block' : 'none'">
  <mat-list-item
    *ngIf="!singleSelect && employees.length > 1"
    role="listitem"
    [class.active]="selection.selected.length === employees.length"
    (click)="selectAllEmployees()"
    class="cursor-pointer"
    >All Employees
  </mat-list-item>
  <ng-container *ngFor="let employee of employees; let index = index">
    <!-- Display first & last names & edit icon -->
    <mat-list-item
      role="listitem"
      [class.active]="selection.selected.length === 1 && selection.selected[0] === employee._id"
      (click)="selectEmployee(employee)"
      class="cursor-pointer"
    >
      {{ employee.firstName }} {{ employee.lastName }}
      <mat-icon style="position: absolute; right: 0; bottom: -15" (click)="handleEditButtonClick(employee)" class="custom-mat-icon" *ngIf="isFlightsAndCosts"
        >edit</mat-icon
      >
    </mat-list-item>
    <mat-divider></mat-divider>

    <!-- Display edit first & last names -->
    <div class="custom-display-first-last-names" *ngIf="isShouldDisplayEditForEmployee(employee)">
      <mat-list-item>
        <mat-form-field class="first-name-display">
          <input #firstName autocomplete="off" placeholder="First Name" matInput [value]="employee.firstName" />
          <mat-error> First Name is <strong>required</strong> </mat-error>
        </mat-form-field>
        <mat-form-field class="last-name-display">
          <input #lastName autocomplete="off" placeholder="Last Name" matInput [value]="employee.lastName" />
          <mat-error> Last Name is <strong>required</strong> </mat-error>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item class="align-items-center">
        <button mat-button (click)="handleCancelButtonClick()">Cancel</button>
        <button mat-button (click)="handleSaveButtonClick(firstName.value, lastName.value, index)">Save</button>
      </mat-list-item>
      <mat-list-item class="align-items-center error-message" *ngIf="isShowBothFieldsRequired">
        {{ bothFieldsRequiredText }}
      </mat-list-item>
      <mat-divider></mat-divider>
    </div>
  </ng-container>
</mat-list>
