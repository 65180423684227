import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { ListTimelineModule } from '../partials/layout/quick-sidebar/list-timeline/list-timeline.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { AsideLeftComponent } from './aside/aside-left.component';
import { FooterComponent } from './footer/footer.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { BrandComponent } from './header/brand/brand.component';
import { MenuSectionComponent } from './aside/menu-section/menu-section.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { CoreModule } from '../../core/core.module';
import { UserProfileComponent } from './header/topbar/user-profile/user-profile.component';
import { SearchDropdownComponent } from './header/topbar/search-dropdown/search-dropdown.component';
import { NotificationComponent } from './header/topbar/notification/notification.component';
import { QuickActionComponent } from './header/topbar/quick-action/quick-action.component';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { AsideRightComponent } from './aside/aside-right/aside-right.component';
import { SearchDefaultComponent } from './header/topbar/search-default/search-default.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import { LanguageSelectorComponent } from './header/topbar/language-selector/language-selector.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

import { TranslateModule } from '@ngx-translate/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { FormsModule } from '@angular/forms';
import { ExpirationDateComponent } from './header/topbar/expiration-date/expiration-date.component';
import { ProgressComponent } from './header/topbar/progress/progress.component';
import { SecondaryMenuHorizontalComponent } from './header/secondary-menu-horizontal/secondary-menu-horizontal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
};

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SubheaderComponent,
    BrandComponent,

    // topbar components
    TopbarComponent,
    UserProfileComponent,
    SearchDropdownComponent,
    NotificationComponent,
    QuickActionComponent,
    LanguageSelectorComponent,

    // aside left menu components
    AsideLeftComponent,
    MenuSectionComponent,

    // horizontal menu components
    MenuHorizontalComponent,
    SecondaryMenuHorizontalComponent,

    // aside right component
    AsideRightComponent,

    SearchDefaultComponent,

    HeaderSearchComponent,

    ProgressComponent,

    ExpirationDateComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SubheaderComponent,
    BrandComponent,

    // topbar components
    TopbarComponent,
    UserProfileComponent,
    SearchDropdownComponent,
    NotificationComponent,
    QuickActionComponent,
    LanguageSelectorComponent,

    // aside left menu components
    AsideLeftComponent,
    // MenuSectionComponent,

    // horizontal menu components
    MenuHorizontalComponent,
    SecondaryMenuHorizontalComponent,

    // aside right component
    AsideRightComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    ListTimelineModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    HttpClientModule,
    TranslateModule.forChild(),
    LoadingBarModule,
    MatProgressSpinnerModule
  ],
})
export class LayoutModule {}
