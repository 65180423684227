import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromAuth from '../content/pages/auth/redux';
import * as fromCurrencies from '../content/pages/currencies';
import * as fromExpenseReports from '../content/pages/expense-report/redux';

export interface AppState {
	auth: fromAuth.AuthState;
	currencies: fromCurrencies.currenciesState;
	expenseReports: fromExpenseReports.ExpenseReportState;
}

export const initialState: AppState = {
	auth: fromAuth.initialState,
	currencies: fromCurrencies.initialState,
	expenseReports: fromExpenseReports.initialState
};


export const reducer: ActionReducerMap<AppState> | any = {
	auth: fromAuth.reducer,
	currencies: fromCurrencies.reducer,
	expenseReports: fromExpenseReports.reducer
};
