import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Employee } from '@employee/employee.model';
import * as fromCompany from '../../../../pages/ngrx/index'
import { AppState } from 'src/app/reducers';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { YesNoDialogArgs } from '@dialogs/defaults/yes-no-dialog/yes-no-dialog.args';
import { DialogsService } from '@dialogs/dialogs.service';
import { take } from 'rxjs/operators';
import { UserService } from '../../../../../core/services/user.service';

@Component({
  selector: 'm-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['.//user-profile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit
{
  @HostBinding('class')
  // tslint:disable-next-line:max-line-length
  classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

  @HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

  @ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

  loggedEmp: Employee;

  showDropdown = false;

  companyName: Observable<string>;

  company: any;

  constructor(
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private dialogsService: DialogsService,
    private userService: UserService
  ) { }

  ngOnInit(): void
  {
    this.authService.getUser().pipe((res: any) => res).subscribe((res: any) => this.loggedEmp = res);
    this.companyName = this.store.pipe(select(fromCompany.selectCompanyName));
    this.store.pipe(select(fromCompany.getCompany)).subscribe(data =>
    {
      this.company = data;
    });
  }

  downloadDocuments()
  {
    if (this.company && this.company.currentAgreement &&
      this.company.currentAgreement.docs && this.company.currentAgreement.docs.length)
    {
      for (const doc of this.company.currentAgreement.docs)
      {
        if (doc.type === "Coverage (employee)" || doc.type === "Traveling policy")
        {
          this.userService.downloadDocument(doc).pipe(take(1)).subscribe();
        }
      }
    }
  }

  public logout()
  {
    //this.authService.logout(true);

    this.dialogsService.openDialog(new YesNoDialogArgs({
      header: 'Logout',
      body: `
      Are you sure you want to log out?
      `,
      noText: 'No',
      yesText: 'Yes',
    }, (result: boolean) =>
      {
        if (result)
        {
          this.authService.logout(true);
        }
      }))


  }
}
