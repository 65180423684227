import { Component, ElementRef, HostBinding, ViewChild, } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { AclService } from './core/services/acl.service';
import { ClassInitService } from './core/services/class-init.service';
import { PageConfigService } from './core/services/page-config.service';
import { TranslationService } from './core/services/translation.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { SplashScreenService } from './core/services/splash-screen.service';

import { filter } from 'rxjs/operators';
import * as objectPath from 'object-path';
// language list
import { locale as enLang } from './config/i18n/en';
import { locale as chLang } from './config/i18n/ch';
import { locale as esLang } from './config/i18n/es';
import { locale as jpLang } from './config/i18n/jp';
import { locale as deLang } from './config/i18n/de';
import { locale as frLang } from './config/i18n/fr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'ginger-dashboard';

	@HostBinding('style') style: any;
	@HostBinding('class') classes: any = '';

	@ViewChild('splashScreen', {read: ElementRef})
	splashScreen: ElementRef;
  splashScreenImage: string = '';

  constructor(
    private layoutConfigService: LayoutConfigService,
		private classInitService: ClassInitService,
		private translationService: TranslationService,
		private splashScreenService: SplashScreenService,
		private pageConfigService: PageConfigService,
		private router: Router,
		private sanitizer: DomSanitizer,
		private aclService: AclService
    ) {
	// subscribe to class update event
	this.classInitService.onClassesUpdated$.subscribe(classes => {
		// get body class array, join as string classes and pass to host binding class
		setTimeout(() => this.classes = classes.body.join(' '));
	});

	this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
		this.classInitService.setConfig(model);

		this.style = '';
		if (objectPath.get(model.config, 'self.layout') === 'boxed') {
			const backgroundImage = objectPath.get(model.config, 'self.background');
			if (backgroundImage) {
				this.style = this.sanitizer.bypassSecurityTrustStyle('background-image: url(' + objectPath.get(model.config, 'self.background') + ')');
			}
		}

		//	splash screen image
		this.splashScreenImage = objectPath.get(model.config, 'loader.image');
	});

	// register translations
	this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);

	// override config by router change from pages config
	this.router.events
		.pipe(filter(event => event instanceof NavigationEnd))
		.subscribe(event => {
			this.layoutConfigService.setModel({page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), 'config')}, true);
		});
  }

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		if (this.splashScreen) {
			this.splashScreenService.init(this.splashScreen.nativeElement);
		}
	}
}
