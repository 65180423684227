<!--begin:: Widgets/Stats2-1 -->
<div class="m-widget1">
  <div class="m-widget1__item">
    <div class="row m-row--no-padding align-items-center">
      <div class="col">
        <h3 class="m-widget1__title">Member Profit</h3>
        <span class="m-widget1__desc">Awerage Weekly Profit</span>
      </div>
      <div class="col m--align-right">
        <span class="m-widget1__number m--font-brand">+$17,800</span>
      </div>
    </div>
  </div>
  <div class="m-widget1__item">
    <div class="row m-row--no-padding align-items-center">
      <div class="col">
        <h3 class="m-widget1__title">Orders</h3>
        <span class="m-widget1__desc">Weekly Customer Orders</span>
      </div>
      <div class="col m--align-right">
        <span class="m-widget1__number m--font-danger">+1,800</span>
      </div>
    </div>
  </div>
  <div class="m-widget1__item">
    <div class="row m-row--no-padding align-items-center">
      <div class="col">
        <h3 class="m-widget1__title">Issue Reports</h3>
        <span class="m-widget1__desc">System bugs and issues</span>
      </div>
      <div class="col m--align-right">
        <span class="m-widget1__number m--font-success">-27,49%</span>
      </div>
    </div>
  </div>
  <div class="m-widget1__item">
    <div class="row m-row--no-padding align-items-center">
      <div class="col">
        <h3 class="m-widget1__title">Sales</h3>
        <span class="m-widget1__desc">Awerage Weekly Sales</span>
      </div>
      <div class="col m--align-right">
        <span class="m-widget1__number m--font-brand">+$50,800</span>
      </div>
    </div>
  </div>
</div>
<!--end:: Widgets/Stats2-1 -->
