<m-portlet>
  <!-- use ng-container[mPortletHeadTitle] for the title -->
  <ng-container mPortletHeadTitle>
    <div class="m-portlet__head-title">
      <h3 class="m-portlet__head-text">Recent Notifications</h3>
    </div>
  </ng-container>

  <!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
  <ng-container mPortletHeadTools>
    <ul class="m-portlet__nav">
      <li
        class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
        m-dropdown-toggle="hover"
      >
        <a href="#" class="m-portlet__nav-link m-dropdown__toggle dropdown-toggle btn btn--sm m-btn--pill btn-secondary m-btn m-btn--label-brand">
          All
        </a>
        <div class="m-dropdown__wrapper">
          <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
          <div class="m-dropdown__inner">
            <div class="m-dropdown__body">
              <div class="m-dropdown__content">
                <ul class="m-nav">
                  <li class="m-nav__section m-nav__section--first">
                    <span class="m-nav__section-text">Quick Actions</span>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-share"></i>
                      <span class="m-nav__link-text">Activity</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-chat-1"></i>
                      <span class="m-nav__link-text">Messages</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-info"></i>
                      <span class="m-nav__link-text">FAQ</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                      <span class="m-nav__link-text">Support</span>
                    </a>
                  </li>
                  <li class="m-nav__separator m-nav__separator--fit"></li>
                  <li class="m-nav__item">
                    <a href="#" class="btn btn-outline-danger m-btn m-btn--pill m-btn--wide btn-sm">Cancel</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>

  <!-- use ng-container[mPortletBody] for the body content -->
  <ng-container mPortletBody>
    <!--Begin::Timeline 3 -->
    <div class="m-timeline-3">
      <div class="m-timeline-3__items">
        <div class="m-timeline-3__item m-timeline-3__item--info">
          <span class="m-timeline-3__item-time">09:00</span>
          <div class="m-timeline-3__item-desc">
            <span class="m-timeline-3__item-text"> Lorem ipsum dolor sit amit,consectetur eiusmdd tempor </span>
            <br />
            <span class="m-timeline-3__item-user-name">
              <a href="#" class="m-link m-link--metal m-timeline-3__item-link"> By Bob </a>
            </span>
          </div>
        </div>
        <div class="m-timeline-3__item m-timeline-3__item--warning">
          <span class="m-timeline-3__item-time">10:00</span>
          <div class="m-timeline-3__item-desc">
            <span class="m-timeline-3__item-text"> Lorem ipsum dolor sit amit </span>
            <br />
            <span class="m-timeline-3__item-user-name">
              <a href="#" class="m-link m-link--metal m-timeline-3__item-link"> By Sean </a>
            </span>
          </div>
        </div>
        <div class="m-timeline-3__item m-timeline-3__item--brand">
          <span class="m-timeline-3__item-time">11:00</span>
          <div class="m-timeline-3__item-desc">
            <span class="m-timeline-3__item-text"> Lorem ipsum dolor sit amit eiusmdd tempor </span>
            <br />
            <span class="m-timeline-3__item-user-name">
              <a href="#" class="m-link m-link--metal m-timeline-3__item-link"> By James </a>
            </span>
          </div>
        </div>
        <div class="m-timeline-3__item m-timeline-3__item--success">
          <span class="m-timeline-3__item-time">12:00</span>
          <div class="m-timeline-3__item-desc">
            <span class="m-timeline-3__item-text"> Lorem ipsum dolor </span>
            <br />
            <span class="m-timeline-3__item-user-name">
              <a href="#" class="m-link m-link--metal m-timeline-3__item-link"> By James </a>
            </span>
          </div>
        </div>
        <div class="m-timeline-3__item m-timeline-3__item--danger">
          <span class="m-timeline-3__item-time">14:00</span>
          <div class="m-timeline-3__item-desc">
            <span class="m-timeline-3__item-text"> Lorem ipsum dolor sit amit,consectetur eiusmdd </span>
            <br />
            <span class="m-timeline-3__item-user-name">
              <a href="#" class="m-link m-link--metal m-timeline-3__item-link"> By Derrick </a>
            </span>
          </div>
        </div>
        <div class="m-timeline-3__item m-timeline-3__item--info">
          <span class="m-timeline-3__item-time">15:00</span>
          <div class="m-timeline-3__item-desc">
            <span class="m-timeline-3__item-text"> Lorem ipsum dolor sit amit,consectetur </span>
            <br />
            <span class="m-timeline-3__item-user-name">
              <a href="#" class="m-link m-link--metal m-timeline-3__item-link"> By Iman </a>
            </span>
          </div>
        </div>
        <div class="m-timeline-3__item m-timeline-3__item--brand">
          <span class="m-timeline-3__item-time">17:00</span>
          <div class="m-timeline-3__item-desc">
            <span class="m-timeline-3__item-text"> Lorem ipsum dolor sit consectetur eiusmdd tempor </span>
            <br />
            <span class="m-timeline-3__item-user-name">
              <a href="#" class="m-link m-link--metal m-timeline-3__item-link"> By Aziko </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--End::Timeline 3 -->
  </ng-container>
</m-portlet>
