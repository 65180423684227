import * as _ from 'lodash';
import { ExpenseReportActions, ExpenseReportActionsTypes } from './expense-report.actions';
import { GridOptions } from 'src/app/core/models/grid/grid-options.model';
import { ExpenseReport } from '../models/expenseReport';
import { SortDirection } from 'src/app/core/models/data';
import { Expense } from '@trip/models/expense.model';

export interface ExpenseReportState extends GridOptions
{
    expenseReports?: ExpenseReport[];
    selectedExpenseReport?: ExpenseReport;
    expenses?: Expense[];
}

export const initialState: ExpenseReportState = {
    page: 0,
    pageSize: 25,
    sort: {
        active: 'createDate',
        direction: SortDirection.DESCENDING
    }
};

export function reducer(state = initialState, action: ExpenseReportActions): ExpenseReportState
{
    switch (action.type)
    {
        case ExpenseReportActionsTypes.updateComments:
            return {
                ...state
            };

        case ExpenseReportActionsTypes.updateApprovals:
            return {
                ...state
            };

        case ExpenseReportActionsTypes.updateExpenseReport:
            const reportIndex = state.expenseReports!.findIndex((report: ExpenseReport) => report.reportId === action.payload.reportId);
            const updated = { ...state.expenseReports![reportIndex], ...action.payload };
            // @ts-ignore
            state.expenseReports[reportIndex] = updated;
            return {
                ...state, selectedExpenseReport: updated
            };

        case ExpenseReportActionsTypes.setExpenseReports:
            return {
                ...state, expenseReports: action.payload
            };

        case ExpenseReportActionsTypes.setSelectedExpenseReport:
            return {
                ...state, selectedExpenseReport: action.payload
            };

        case ExpenseReportActionsTypes.updateExpenseReports:
            return {
                ...state, expenseReports: [...state.expenseReports!]
            };

        case ExpenseReportActionsTypes.setExpenses:
            return {
                ...state, expenses: action.payload
            };

        case ExpenseReportActionsTypes.deleteExpenseReport:
            const reports = state.expenseReports!.filter(report => report._id !== action.payload._id);
            return {
                ...state, expenseReports: [...reports]
            };

        default: {
            return state;
        }
    }
}