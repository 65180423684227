import { Component, OnInit } from '@angular/core';
import { LogsService } from '../../../../../../core/services/logs.service';

@Component({
  selector: 'm-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit {
  logList: any;
  logList2: any;
  logList3: any;

  constructor(private logsService: LogsService) {}

  ngOnInit() {}
}
