<div style="display: flex; align-items: center" class="m-container m-page__container m-container--full-height" [ngClass]="(footerContainerClass$ | async) || 'default-class'">
	<div class="m-footer__wrapper">
		<div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
			<div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
			<span class="m-footer__copyright" style="font-size: 1rem;">
				2023 &copy; Powered by 
			</span>
			<a href="https://selacloud.com/" target="_blank"><img style="height: 1.5rem; margin-left: 0.3rem;" src="../../../assets/app/sela-logo-black.svg"/></a>
			</div>
			<div class="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
				<ul class="m-footer__nav m-nav m-nav--inline m--pull-right">
					<li class="m-nav__item m-nav__item">
						<a href="mailto:office@gingerbit.co.il" class="m-nav__link" data-toggle="m-tooltip" title="Support Center" data-placement="left" style="font-size: 1rem;">
								office@gingerbit.co.il
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
