import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "./reducers";
import { FullEmployeeDetail } from "../../../../../employee/employee.model";
import { GeneralInformationModel, GeneralInformationFullModel } from "../new-trip-general-information/new-trip-general-information.form-group";

export { reducer, initialState, State } from './reducers';

function getFullEmployee(state: State): FullEmployeeDetail[] {
    const {
        employees,
        details,
        files,
        notes,
    } = state;
    return employees.map((employee) => {
        const _files = files.filter(f => f.employeesGuids.indexOf(employee._id) > -1);
        const flightAndHotels = details[employee._id] || { employeeId: employee._id };
        const _notes = notes.filter(n => n.employeeId === employee._id);
        return ({
            ...employee,
            files: _files,
            flightAndHotels,
            notes: _notes
        })
    })
}

export const getNewTrip = createFeatureSelector<State>('newTrip');
export const getSelectedEmployees = createSelector(getNewTrip, (state) => state.employees);
export const getDepartureDate = createSelector(getNewTrip, (state) => state.departureDate);
export const getDestination = createSelector(getNewTrip, (state) => state.destination);
export const getDetails = createSelector(getNewTrip, (state) => state.details);
export const getFiles = createSelector(getNewTrip, (state) => state.files);
export const getLoading = createSelector(getNewTrip, (state) => state.loading);
export const getNotes = createSelector(getNewTrip, (state) => state.notes);
export const getReturnDate = createSelector(getNewTrip, (state) => state.returnDate);
export const getStep = createSelector(getNewTrip, (state) => state.step);
export const getGeneralInformation = createSelector(getNewTrip, (state): GeneralInformationModel => {
    const { employees,
        departureDate,
        returnDate,
        destination } = state;
    return {
        employees,
        departureDate,
        returnDate,
        destination
    }
})
export const getFullInformation = createSelector(getNewTrip, (state): GeneralInformationFullModel => {
    const {
        departureDate,
        returnDate,
        destination } = state;
    return {
        employees: getFullEmployee(state),
        departureDate,
        returnDate,
        destination
    }
})
export const getIfGeneralInformationFulfill = createSelector(getNewTrip, (state) => {
    const { employees,
        departureDate,
        returnDate,
        destination } = state;
    return (
        employees.length > 0 &&
        !!departureDate &&
        !!returnDate &&
        !!destination
    );
})

export const getAllEmployeeData = createSelector(getNewTrip, getFullEmployee)

export const doesNewTripContainsData = createSelector(getNewTrip, (trip=>trip.userInputedDataToNewTrip))