<div
  style="
    background-image: url(assets/app/media/img/bg/bg-4.jpg);
    height: 100%;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-family: 'Roboto';
    font-weight: 500;
  "
>
  <div class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-login__wrapper" style="padding: 50px">
    <div class="m-login__body" style="padding: 20px; background-color: white; max-width: 600px; margin-left: auto; margin-right: auto">
      <div class="m-login__title">
        <img alt="" style="width: 150px; margin-top: 7px" src="./assets/app/media/img/logos/ginger-logo.png" />
      </div>

      <div class="m-login__title" style="color: black; margin: 10px">
        {{ translate.instant('POLYWIZZ.TITLE.MEETING_CONFIRMATION') }}
      </div>
      <div class="m-login__title" style="color: black; margin: 10px">
        {{ translate.instant('POLYWIZZ.TITLE.MEETING_CONFIRMATION_DESCRIPTION') }}
      </div>

      <form class="m-login__form m-form" name="form" (ngSubmit)="submit()" #f="ngForm" novalidate>
        <div class="form-group">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input
              matInput
              type="text"
              name="firstName"
              placeholder="First name"
              autocomplete="off"
              (keyup.enter)="submit()"
              [(ngModel)]="model.firstName"
              #firstName="ngModel"
              required
            />
            <mat-error *ngIf="firstName.errors && firstName.errors['required']"> First Name is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input
              matInput
              type="text"
              name="lastName"
              placeholder="Last name"
              autocomplete="off"
              (keyup.enter)="submit()"
              [(ngModel)]="model.lastName"
              #lastName="ngModel"
              required
            />
            <mat-error *ngIf="lastName.errors && lastName.errors['required']"> Last Name is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label>Date of Birth</mat-label>
            <input
              matInput
              name="dateOfBirth"
              autocomplete="off"
              (keyup.enter)="submit()"
              [(ngModel)]="model.dateOfBirth"
              #dateOfBirth="ngModel"
              #dateOfBirthBlur
              [max]="maxDate"
              [matDatepicker]="picker_dateOfBirth"
              readonly
              required
              (focus)="picker_dateOfBirth.open()"
            />

            <mat-datepicker-toggle matSuffix [for]="picker_dateOfBirth"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #picker_dateOfBirth (closed)="dateOfBirthBlur.blur()"></mat-datepicker>

            <mat-error *ngIf="dateOfBirth.errors && dateOfBirth.errors['required']"> Date of Birth is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label>ID Number</mat-label>
            <input
              matInput
              type="text"
              name="insuredId"
              placeholder="ID number"
              autocomplete="off"
              (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
              (keyup.enter)="submit()"
              [(ngModel)]="model.insuredId"
              #insuredId="ngModel"
              required
            />
            <mat-error *ngIf="insuredId.errors && insuredId.errors['required']"> ID Number is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label>ID Issue Date (not date of birth!)</mat-label>
            <input
              matInput
              name="idIssueDate"
              autocomplete="off"
              (keyup.enter)="submit()"
              [(ngModel)]="model.idIssueDate"
              #idIssueDate="ngModel"
              #idIssueDateBlur
              [max]="maxDate"
              [matDatepicker]="picker"
              readonly
              required
              (focus)="picker.open()"
            />

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #picker (closed)="idIssueDateBlur.blur()"></mat-datepicker>

            <mat-error *ngIf="idIssueDate.errors && idIssueDate.errors['required']"> ID Issue Date is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              name="email"
              placeholder="Email address"
              autocomplete="off"
              (keyup.enter)="submit()"
              [(ngModel)]="model.email"
              #email="ngModel"
              email="true"
              required
            />
            <mat-error *ngIf="email.errors && email.errors['email']"> Please enter a valid email address </mat-error>
            <mat-error *ngIf="email.errors && email.errors['required']"> Email is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              type="text"
              name="phoneNumber"
              placeholder="Phone number in international format (including +)"
              autocomplete="off"
              (keypress)="(($event.charCode >= 48 && $event.charCode < 58) || $event.charCode === 43)"
              (keyup.enter)="submit()"
              [(ngModel)]="model.phoneNumber"
              #phoneNumber="ngModel"
              required
            />
            <mat-error *ngIf="phoneNumber.errors && phoneNumber.errors['required']"> Phone Number is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>
      </form>

      <div class="m-login__title" style="color: black; margin: 10px">
        {{ translate.instant('POLYWIZZ.TITLE.REVIEW_REQUEST') }}
      </div>

      <div class="m-login__action m-login__action--fit" style="justify-content: center; flex-direction: column">
        <m-spinner-button [options]="spinner" (click)="submit()">Check</m-spinner-button>
      </div>
    </div>
  </div>
</div>
