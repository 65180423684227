<m-portlet [options]="{ headOverlay: true }">
  <!-- use ng-container[mPortletHeadTitle] for the title -->
  <ng-container mPortletHeadTitle>
    <div class="m-portlet__head-action">
      <button type="button" class="btn btn-sm m-btn--pill btn-brand">Blog</button>
    </div>
  </ng-container>

  <!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
  <ng-container mPortletHeadTools></ng-container>

  <!-- use ng-container[mPortletBody] for the body content -->
  <ng-container mPortletBody>
    <div class="m-widget19">
      <div class="m-widget19__pic m-portlet-fit--top m-portlet-fit--sides" style="min-height-: 286px">
        <img src="./assets/app/media/img//blog/blog1.jpg" alt="" />
        <h3 class="m-widget19__title m--font-light">Introducing New Feature</h3>
        <div class="m-widget19__shadow"></div>
      </div>
      <div class="m-widget19__content">
        <div class="m-widget19__header">
          <div class="m-widget19__user-img">
            <img class="m-widget19__img" src="./assets/app/media/img//users/user1.jpg" alt="" />
          </div>
          <div class="m-widget19__info">
            <span class="m-widget19__username"> Anna Krox </span><br />
            <span class="m-widget19__time"> UX/UI Designer, Google </span>
          </div>
          <div class="m-widget19__stats">
            <span class="m-widget19__number m--font-brand"> 18 </span>
            <span class="m-widget19__comment"> Comments </span>
          </div>
        </div>
        <div class="m-widget19__body">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry scrambled it to make text of the printing and typesetting industry
          scrambled a type specimen book text of the dummy text of the printing printing and typesetting industry scrambled dummy text of the
          printing.
        </div>
      </div>
      <div class="m-widget19__action">
        <button type="button" class="btn m-btn--pill btn-secondary m-btn m-btn--hover-brand m-btn--custom">Read More</button>
      </div>
    </div>
  </ng-container>
</m-portlet>
