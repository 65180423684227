<div>
  <div class="file-upload-container">
    <button
      mat-button
      (click)="fileInput.click()"
      style="
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: bold;
        color: #6f42c1;
      "
    >
      Upload
    </button>
    <input
      #fileInput
      type="file"
      #addFiles
      (change)="onFilesAdded()"
      style="display: none"
      multiple
      accept=".jpg,.jpeg,.png,.pdf"
    />
    
    <div class="dropdown-custom">
      <div
        style="
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: bold;
        "
        [style.color]="getTextColor()"
      >
        {{ getReceiptsDisplayText() }}
      </div>
      <div class="dropdown-content" *ngIf="getNumberOfFiles() > 0">
        <mat-list>
          <div *ngFor="let file of getFiles()">
            <div class="display-in-a-row">
              <div class="left-text">
                <h5 mat-line>{{ displayableFileName(file.name) }}</h5>
              </div>
              <div class="right-icons">
                <button *ngIf="file.uploaded" class="remove-button-style">
                  <a
                    target="_blank"
                    *ngIf="file.file"
                    [attr.download]="file.name"
                    [attr.href]="createDownloadUrl(file)"
                  >
                    <mat-icon mat-list-icon>cloud_download</mat-icon>
                  </a>
                  <a
                    target="_blank"
                    *ngIf="file.downloadUrl"
                    (click)="downloadFile(file.name, file.downloadUrl)"
                  >
                    <mat-icon mat-list-icon>cloud_download</mat-icon>
                  </a>
                </button>
                <button class="remove-button-style">
                  <a>
                    <mat-icon (click)="remove(file)" mat-list-icon
                      >cancel</mat-icon
                    >
                  </a>
                </button>
              </div>
            </div>
          </div>
        </mat-list>
      </div>
    </div>

    <div *ngIf="loading" class="spinner-background col-sm-12">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
