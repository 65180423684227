import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ForgotPasswordResetDialogComponent } from '../forgot-password-reset-dialog/forgot-password-reset-dialog.component';

@Component({
  selector: 'm-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(private dialog: MatDialog) {}
  ngOnInit() {
    setTimeout(() => {
      this.dialog.open(ForgotPasswordResetDialogComponent, {
        width: '300px',

        panelClass: 'dialogForgotPassword',
        disableClose: true,
        hasBackdrop: false,
      });
    }, 150);
  }
}
