import { currenciesState, initialState, reducer, selectAll ,selectEntities} from './currencies.reducer'
import { createSelector, createFeatureSelector } from '@ngrx/store';

export { currenciesState, initialState, reducer }

export const selectCurrenciesState = createFeatureSelector<currenciesState>('currencies');

export const defaultCurrencyCode = (state: currenciesState) => state.companysDefaultCurrencyCode;

export const companysDefaultCurrencyCode = createSelector(
    selectCurrenciesState,
    selectAll
);
 
export const selectAllCurrencies = createSelector(
    selectCurrenciesState,
    selectAll
);
export const selectCurrencyEntities = createSelector(
    selectCurrenciesState,
    selectEntities
);

export const getCurrency = createSelector(
    selectCurrencyEntities,
    (currencies: any, props: any)=>currencies[props.currencyCode]
);
export const companysDefaultCurrency = createSelector(
    selectCurrenciesState,
    (state: currenciesState) => state.companysDefaultCurrencyCode,
    (currencies: any, defaultCurrencyCode: any)=>{currencies[defaultCurrencyCode]}
);
