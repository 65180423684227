<m-portlet [options]="{ class: ['m--bg-warning', 'm-portlet--bordered-semi'] }">
  <!-- use ng-container[mPortletHeadTitle] for the title -->
  <ng-container mPortletHeadTitle>
    <div class="m-portlet__head-title">
      <h3 class="m-portlet__head-text m--font-light">Packages</h3>
    </div>
  </ng-container>

  <!-- use ng-container[mPortletHeadTools] for the top right toolbar content -->
  <ng-container mPortletHeadTools>
    <ul class="m-portlet__nav">
      <li
        class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
        m-dropdown-toggle="hover"
      >
        <a href="#" class="m-portlet__nav-link m-dropdown__toggle dropdown-toggle btn btn--sm m-btn--pill m-btn btn-outline-light m-btn--hover-light">
          2018
        </a>
        <div class="m-dropdown__wrapper">
          <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
          <div class="m-dropdown__inner">
            <div class="m-dropdown__body">
              <div class="m-dropdown__content">
                <ul class="m-nav">
                  <li class="m-nav__section m-nav__section--first">
                    <span class="m-nav__section-text">Reports</span>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-share"></i>
                      <span class="m-nav__link-text">Activity</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-chat-1"></i>
                      <span class="m-nav__link-text">Messages</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-info"></i>
                      <span class="m-nav__link-text">FAQ</span>
                    </a>
                  </li>
                  <li class="m-nav__section m-nav__section--first">
                    <span class="m-nav__section-text">Export</span>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                      <span class="m-nav__link-text">PDF</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                      <span class="m-nav__link-text">Excel</span>
                    </a>
                  </li>
                  <li class="m-nav__item">
                    <a href="" class="m-nav__link">
                      <i class="m-nav__link-icon flaticon-lifebuoy"></i>
                      <span class="m-nav__link-text">CSV</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>

  <!-- use ng-container[mPortletBody] for the body content -->
  <ng-container mPortletBody>
    <!--begin::Widget 29-->
    <div class="m-widget29">
      <div class="m-widget_content">
        <h3 class="m-widget_content-title">Monthly Income</h3>
        <div class="m-widget_content-items">
          <div class="m-widget_content-item">
            <span>Total</span>
            <span class="m--font-accent">$680</span>
          </div>
          <div class="m-widget_content-item">
            <span>Change</span>
            <span class="m--font-brand">+15%</span>
          </div>
          <div class="m-widget_content-item">
            <span>Licenses</span>
            <span>29</span>
          </div>
        </div>
      </div>
      <div class="m-widget_content">
        <h3 class="m-widget_content-title">Taxes info</h3>
        <div class="m-widget_content-items">
          <div class="m-widget_content-item">
            <span>Total</span>
            <span class="m--font-accent">22.50</span>
          </div>
          <div class="m-widget_content-item">
            <span>Change</span>
            <span class="m--font-brand">+15%</span>
          </div>
          <div class="m-widget_content-item">
            <span>Count</span>
            <span>701</span>
          </div>
        </div>
      </div>
      <div class="m-widget_content">
        <h3 class="m-widget_content-title">Partners Sale</h3>
        <div class="m-widget_content-items">
          <div class="m-widget_content-item">
            <span>Total</span>
            <span class="m--font-accent">$680</span>
          </div>
          <div class="m-widget_content-item">
            <span>Change</span>
            <span class="m--font-brand">+15%</span>
          </div>
          <div class="m-widget_content-item">
            <span>Licenses</span>
            <span>29</span>
          </div>
        </div>
      </div>
    </div>
    <!--end::Widget 29-->
  </ng-container>
</m-portlet>
