import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatInput,  } from '@angular/material/input';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { MyErrorStateMatcher } from '../forgot-password-dialog/forgot-password-dialog.component';
import { catchError } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password-reset-dialog',
  templateUrl: './forgot-password-reset-dialog.component.html',
  styleUrls: ['./forgot-password-reset-dialog.component.css']
})
export class ForgotPasswordResetDialogComponent implements OnInit {
  @ViewChild(MatInput) emailInput: MatInput;
  @ViewChild('passwordComponentWithValidation') newPasswordComponentRef: ElementRef;
  token: string;
  subscriptions: Subscription[] = [];
  showPassword = false;

  loading = false;

  message = '';

  matcher = new MyErrorStateMatcher();

  dynamicName = new Date().getTime();

  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    confirmPassword: new FormControl('', [Validators.required])
  }, (this.pwdMatchValidator as any));

  get confirm_password() { return this.formGroup.get('confirmPassword'); }
  submit() {

    this.loading = true;

    let newPassword: string = "";
    if (this.formGroup.controls["confirmPassword"]) {
      newPassword = this.formGroup.controls["confirmPassword"].value;
    }
    if (this.formGroup.controls["newPassword"]) {
      newPassword = this.formGroup.controls["newPassword"].value;
    }

    const data =
    {
      email: this.formGroup.controls['email'].value,
      newPassword: newPassword
    };

    this.subscriptions.push(this.httpClient.post(`${environment.api_endpoint}/auth/forgot-password/verify`, data, { headers: { 'resetpassword': this.token } })
      .pipe(
        catchError(e => {
          this.loading = false;
          alert('Reset password failed');
          return of(null);
        })
      )
      .subscribe((d: { message: string } | any) => {
        this.loading = false;
        alert('change password succeeded');
        this.dialogRef.close();
        this.router.navigate(['/']);
      }, (e) => {
        this.message = e.message || e.error.message;
      }));
  }

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private router: Router,
    private dialogRef: MatDialogRef<ForgotPasswordResetDialogComponent>) { }

  pwdMatchValidator(frm: FormGroup) {
    if (!frm.get('newPassword'))
    { return null; }
    const newPasswordValue = frm.get('newPassword')!.value;
    const confirmPasswordValue = frm.get('confirmPassword')!.value;
    return newPasswordValue === confirmPasswordValue ? null : { 'mismatch': true };
  }

  ngOnInit() {
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    }));
    setTimeout(() => {
      this.emailInput.focus();
    }, 500);
    if (this.newPasswordComponentRef) {
      const formControl = (this.newPasswordComponentRef as any).passwordFormControl;
      this.formGroup.addControl('newPassword', formControl);
    }
  }

  OnDestroy() {
		this.subscriptions.forEach((subs) => {
			subs.unsubscribe();
		});
	}
}

export class MyErrorStateMatcher1 implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
