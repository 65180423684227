import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptsUploaderComponent } from './receipts-uploader.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UsersSelectionsModule } from '../users-selections/users-selections.module';

@NgModule({
  imports: [
    CommonModule,
    NgxFileDropModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    UsersSelectionsModule,
  ],
  declarations: [ReceiptsUploaderComponent],
  exports: [ReceiptsUploaderComponent]
})
export class ReceiptsUploaderModule { }
