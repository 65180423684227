<div
  style="
    background-image: url(assets/app/media/img/bg/bg-4.jpg);
    height: 100%;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-family: 'Roboto';
    font-weight: 500;
  "
>
  <h1>Ginger</h1>
</div>
